@import "/src/ui/assets/styles/variables";


.org-setting-profile-container {
    width: 60rem;
    margin: $margin-3 auto;
    padding: $padding-5 0;

    .org-setting-profile-title {
        @include title-1;
        color: $grey-color-2;
        padding: $padding-3 0;
        margin-bottom: $margin-4;
    }

    .org-profile-section {
        margin-bottom: $margin-4;
        border: $border-width-1 solid $grey-color-4;
        border-radius: $border-radius-2;
        padding: $padding-3;
    }

    .alert-bar {
        margin-bottom: $margin-4;
    }
}

