@import "/src/ui/assets/styles/variables";

.past-event-recording-container {

    .past-event-recording-wrapper {
        display: flex;
        gap: $gap-5;

        .past-event-recording-search {
            flex: 1;
        }

        .past-event-recording-content {
            flex: 1;

            .past-event-video-head {
                margin-bottom: $margin-3;

                .past-event-video-title {
                    @include title-2;
                    margin-bottom: $margin-2;
                }

                .past-event-video-release-time {
                    @include text-3;
                    font-weight: 300;
                    color: $slate-color-4;
                    margin-bottom: $margin-2;
                }

                .past-event-video-privacy {
                    display: flex;
                    align-items: center;
                    gap: $gap-1;

                    p {
                        @include text-4;
                        font-weight: 300;
                        font-style: italic;
                        color: $slate-color-3;
                    }
                }
            }

            .past-event-video-player {
                width: 40rem;
                aspect-ratio: 3/2;
                height: unset;
                border-radius: $border-radius-3;
                overflow: hidden;
                margin-bottom: $margin-3;

                video {
                    width: 100% !important;
                    height: 100% !important;
                }
            }

            .past-event-video-description {
                .past-event-video-description-title {
                    @include title-4;
                    margin-bottom: $margin-2;
                }

                .past-event-video-description-text {
                    @include text-2;
                }
            }
        }
    }

    .past-event-no-video {
        width: 100%;
        height: 20rem;
        display: flex;
        align-items: center;
        justify-content: center;
        @include title-2;
        color: $slate-color-3;
    }
}
