@import "/src/ui/assets/styles/variables";

.meeting-summary-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $gap-3;

    .meeting-summary-section-title {
        @include text-3;
        color: $slate-color-3;
        margin-bottom: $margin-2;
    }

    .meeting-summary-section {
        .meeting-datetime-info {
            @include text-1;
            color: $secondary-color;
            display: flex;
            flex-direction: column;
            gap: $gap-1;

            .meeting-info-date,
            .meeting-info-time {
                display: flex;
                align-items: center;
                gap: $gap-2;
            }
        }

        .meeting-provider-info {
            display: flex;
            align-items: center;
            gap: $gap-2;

            .meeting-provider-logo {
                img {
                    width: 1.2rem;
                    height: auto;
                    object-fit: cover;
                }
            }

            .meeting-provider-name {
                @include text-1;
                color: $slate-color-1;
            }
        }

        .meeting-organizer-info {
            height: 5rem;
            display: flex;
            align-items: center;
            gap: $gap-2;

            .meeting-organizer-avatar {
                width: 5rem;
                height: 100%;
                border-radius: $border-radius-2;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .meeting-organizer-detail {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .meeting-organizer-name {
                    @include text-1;
                    color: $secondary-color;
                }
            }
        }
    }
}