@import '/src/ui/assets/styles/variables.scss';

.voucher-drawer-contents {
	height: 100%;
	.voucher-drawer-form-container {
		height: 100%;
		position: relative;

		.ant-input-number-affix-wrapper  {
			width: 100%;
		}

		.ant-input-number {
			width: 100%;
		}

		.amount-discount {
			display: flex;
			flex-direction: column;
			gap: $gap-2;

			.amount-discount-inputs {
				display: flex;
				gap: $gap-1;
				align-items: center;

				.percent-off,
				.amount-off{
					flex: 1;
				}

				.or-text {
					padding-bottom: $padding-5;
				}
			}
		}

		.voucher-drawer-buttons {
			display: flex;
			gap: $gap-2;
			position: absolute;
			bottom: 0;
			width: 100%;

			.cancel-button-container,
			.save-button {
				flex: 1;
			}
		}
	}
}