@import "src/ui/assets/styles/variables.scss";

.meeting-id-copy-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: $border-width-1 solid $slate-color-3;
    border-radius: $border-radius-2;
    padding: $padding-2 $padding-3;
    color: $slate-color-6;
}