@import "/src/ui/assets/styles/variables";

.ticket-cart-container {
    width: min(100%, 20rem);
    display: flex;
    flex-direction: column;
    gap: $gap-3;
    padding: $padding-5;
    border: $border-width-1 solid $grey-color-4;
    border-radius: $border-radius-3;

    .ticket-cart-section {
        .ticket-cart-section-title {
            @include title-3;
            color: $slate-color-1;
            padding: $padding-2 0;
            margin-bottom: $margin-1;
        }
    }

    .ticket-cart-buyer {
        .ticket-cart-buyer-info {
            display: flex;
            flex-direction: column;
            gap: $gap-1;

            .ticket-cart-buyer-info-item {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .info-item-label {
                    @include title-4;
                    color: $secondary-color;
                }

                .info-item-content {
                    @include text-2;
                    color: $secondary-color-tint-2;
                }
            }
        }
    }

    .ticket-cart-order {
        .ticket-cart-form {
            .ticket-cart-form-head {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: $margin-2;

                p {
                    @include text-2;
                    color: $primary-color;
                }
            }

            .ticket-cart-form-item-list {
                transition: all 1s ease-in-out;
                height: fit-content;

                .ticket-cart-form-item {
                    padding: $padding-2 0;
                    border-top: $border-width-1 solid $slate-color-6;
                    display: grid;
                    row-gap: $gap-1;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(2, 1fr);

                    .cart-item-ticket-type {
                        @include title-4;
                        color: $secondary-color;
                    }

                    .cart-item-quantity {
                        text-align: right;
                        @include text-3;
                        color: $slate-color-1;
                    }

                    .cart-item-unit-price {
                        @include text-3;
                        color: $slate-color-3;
                        font-weight: 300;
                        font-style: italic;
                    }

                    .cart-item-price {
                        text-align: right;
                        @include text-3;
                        color: $slate-color-1;
                    }
                }
            }

            .ticket-cart-form-footer {
                margin-top: $margin-2;
                display: flex;
                flex-direction: column;
                gap: $gap-1;

                .ticket-cart-form-footer-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .form-footer-label {
                        @include title-4;
                        color: $secondary-color;
                    }

                    .form-footer-content {
                        @include text-2;
                        color: $secondary-color;
                    }

                    &.ticket-cart-total {
                        margin-top: $margin-1;

                        .form-footer-label {
                            @include title-3;
                            color: $secondary-color;
                        }

                        .form-footer-content {
                            @include title-3;
                            color: $primary-color;
                        }
                    }
                }
            }
        }
    }
}