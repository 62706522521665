@import '/src/ui/assets/styles/spacing.scss';
@import '/src/ui/assets/styles/typography.scss';

.layout-info-container {
    display: flex;
    justify-content: space-around;

    .layout-info-content {
        width: 75%;
        .info-text {
            h1 {
                @include event-heading-1;
                letter-spacing: .5px;
            }
        }

        .info-formfields {
            margin-top: $margin-1;
        }
    }

}