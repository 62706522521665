@import '/src/ui/assets/styles/variables.scss';

.manage-event-layout {
    display: flex;
    height: 100%;
    overflow-y: hidden;

    .side-nav {
        height: 100%;
        width: 18rem;
        background-color: $slate-color-6;
        position: static;
    }

    .main-event-layout {
        width: 100%;
        overflow: scroll;
    }
}