@import "/src/ui/assets/styles/variables";

.message-container {
    width: 100%;
    height: 4.5rem;
    display: flex;
    padding: $padding-3;
    box-sizing: border-box;
    gap: $gap-1;
    align-items: center;
    cursor: pointer;

    &:hover {
        border-radius: $border-radius-6;
        background: $slate-color-5;
    }

    .image-container {
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 3rem;
            height: 3rem;
            border-radius: $border-radius-rounded;
            object-fit: contain;
        }
    }

    .message-info {
        width: 100%;

        .sender-name {
            @include text-3;
            color: $black;
            font-weight: 600;
        }

        .message-content {
            @include text-4;
            font-weight: 500;
            color: $slate-color-2;
        }
    }

    .time-sent {
        .time {
            @include text-3;
            color: $slate-color-2;
            font-weight: 600;
        }
    }
}