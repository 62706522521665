@import "/src/ui/assets/styles/variables";

.profile-avatar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $margin-3;

    .profile-avatar-image {
        width: 8rem;
        height: 8rem;
        border-radius: $border-radius-3;
        overflow: hidden;
        margin-right: $margin-3;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .profile-avatar-btn {
        display: flex;
        flex-direction: row;
        gap: $gap-2;
        align-items: center;
    }
}
