@import "/src/ui/assets/styles/variables";

.online-event-container {
    width: max(50rem, 50%);
    margin: 0 auto;

    .online-event-page-title {
        @include title-1;
        margin-bottom: $margin-4;
    }

    .online-event-content {
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: $gap-5;
    }

    .online-event-message {
        @include heading-1;
        color: $slate-color-5;
        margin: $margin-6 auto;
        text-align: center;
    }
}