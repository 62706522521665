@import '/src/ui/assets/styles/variables';

.search-on-map-container {
    height: 40rem;
    border-radius: $border-radius-3;
    overflow: hidden;
    position: relative;
    
    .search-on-map-minimize-btn {
        position: absolute;
        bottom: 1rem;
        left: 1rem;
    }
}