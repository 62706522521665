@import "/src/ui/assets/styles/variables";

.ticket-option-form-container {
    display: flex;
    flex-direction: column;
    gap: $gap-2;

    .ticket-option-form-head,
    .ticket-option-form-item {
        display: flex;
        align-items: center;
        padding: $padding-3;
        border-radius: $border-radius-2;

        .ticket-option-col-title {
            @include title-3;
        }

        .ticket-option-col-1 {
            flex: 4 4 36rem;
        }

        .ticket-option-col-2,
        .ticket-option-col-3 {
            flex: 1 1 10rem;
        }
    }

    .ticket-option-form-head {
        background-color: $light-background-white-2;
    }

    .ticket-option-form-item {
        border: $border-width-1 solid $grey-color-4;

        &.sold-out {
            color: $grey-color-2;
            background-color: $grey-color-4;
        }

        .ticket-type {
            @include text-1;
            cursor: pointer;

            &:hover {
                color: $primary-color;
            }
        }

        .ticket-price {
            @include title-3;
        }
    }
}

.ticket-option-detail {
    .ticket-detail-slot,
    .ticket-detail-due-date {
        display: block;
        margin-bottom: $margin-2;
    }

    .ticket-detail-description {
        margin-top: $margin-3;
        color: $slate-color-2;
        @include text-2;
    }
}