@import '/src/ui/assets/styles/variables.scss';

.organizer-card-container {
    width: 12rem;
    padding: $padding-4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: $border-radius-3;
    border: $border-width-1 solid $slate-color-6;
    transition: box-shadow 0.25s ease-in-out;
    background-color: $white;

    &:hover {
        box-shadow: $box-shadow-1;
    }

    .organizer-card-head {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $gap-1;

        .organizer-card-avatar {
            width: 5rem;
            height: 5rem;
            border-radius: $border-radius-rounded;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .organizer-card-name {
            @include title-4;
            text-align: center;
            color: $secondary-color;
        }

        .organizer-card-follower {
            @include text-3;
            color: $grey-color-2;
            text-align: center;
        }
    }
}