@import '/src/ui/assets/styles/variables.scss';


.event-address-container {
    width: 100%;

    .event-address-info {
        width: 100%;
        margin-bottom: $margin-1;
        display: flex;
        align-items: flex-start;
        gap: $gap-2;

        .ant-typography {
            margin-bottom: 0;
        }

        .ant-typography-copy {
            color: $grey-color-2;
        }

        .event-address-main {
            display: flex;
            flex-direction: column;
            gap: $gap-1;
            align-items: flex-start;

            .event-address-btn {
                display: flex;
                gap: $gap-1;
                align-items: center;
            }
        }
    }

    .event-address-map {
        margin: $margin-2 0;
        width: 100%;

        .event-address-direction {
            margin-bottom: $margin-2;
            display: flex;
            flex-direction: column;
            gap: $gap-1;
            align-items: center;
        }

        .event-address-map-graphic {
            width: 100%;
            height: auto;
            aspect-ratio: 16 / 9;
            border-radius: $border-radius-3;
            overflow: hidden;

            @container (max-width: 48rem) {
                width: 100%;
                display: block;
                box-sizing: border-box;
            }
        }
    }
}

.event-address-map-info-window {
    padding: $padding-1;

    p {
        margin-bottom: $margin-2;
    }

    .event-address-map-event {
        @include title-3;
        color: $primary-color;
    }
}