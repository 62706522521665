@import "/src/ui/assets/styles/variables";

.user-page-container {
    //width: 100%;

    .user-page-header {
        margin-bottom: $margin-5;
        background: $slate-color-5;
        background: linear-gradient(
                        180deg,
                        #eafaf3 0%,
                        #fcfffc 35%,
                        #fdfffe 100%
        );
    }

    .user-page-main {
        width: 70rem;
        margin: 0 auto;
        display: flex;
        gap: $gap-3;

        .user-page-menu {
            flex: 1 1 5rem;
            padding: $padding-3;

            .user-page-menu-title {
                @include heading-3;
                margin-bottom: $margin-2;
            }
        }

        .user-page-content {
            flex: 4;
            padding: $padding-3;
        }
    }
}

@media screen and (max-width: 48rem) {
    .user-page-container {
        //height: 100%;

        .user-page-header {
            display: none;
        }

        .user-page-main {
            //margin-bottom: $margin-6;
            width: 100%;
            height: 100%;

            .user-page-menu {
                display: none;
            }

            .user-page-content {
                width: 100%;
                height: 100%;

                .user-liked-events-container {
                    height: 100%;

                    .vertical-event-list-container {
                        height: 100%;
                    }
                }
            }
        }
    }

}