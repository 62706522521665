@import '/src/ui/assets/styles/variables.scss';


.user-info-form-container {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: $gap-5;

    .info-form-title {
        @include text-2;
        color: $secondary-color-tint-2;
        text-align: center;
    }
}

.ant-picker-today-btn {
    color: $primary-color !important;
}