@mixin heading-1 {
    font-size: 2rem;
    font-weight: 700;
    line-height: normal;
}

@mixin heading-2 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: normal;
}

@mixin heading-3 {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: normal;
}

@mixin heading-4 {
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
}

@mixin text-1 {
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
}

@mixin text-2 {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: normal;
}

@mixin text-3 {
    font-size: 0.8rem;
    font-weight: 400;
    line-height: normal;
}

@mixin text-4 {
    font-size: 0.75rem;
    font-weight: 300;
    line-height: normal;
}

@mixin text-5 {
    font-size: 0.7rem;
    font-weight: 300;
    line-height: normal;
}

@mixin text-6 {
    font-size: .875rem;
    line-height: 1.25rem;
    font-weight: 600;
}


@mixin text-mobile-4 {
    font-size: 0.6rem;
    font-weight: 500;
}


@mixin text-mobile-5 {
    font-size: 0.5rem;
    font-weight: 400;
}


@mixin badge-1 {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: normal;
}

@mixin button-1 {
    font-size: 1.1rem;
    font-weight: 500;
    line-height: normal;
}

@mixin button-2 {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: normal;
}

@mixin menu {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: normal;
}

@mixin caption {
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
}

@mixin title-1 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: normal;
}

@mixin title-2 {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: normal;
}

@mixin title-3 {
    font-size: 1rem;
    font-weight: 500;
    line-height: normal;
}

@mixin title-4 {
    font-size: 0.9rem;
    font-weight: 500;
    line-height: normal;
}


@mixin label-1 {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: normal;
}

@mixin table-cell {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: normal;
}


@mixin event-heading-1 {
    font-size: 1.875rem;
    line-height: 2.5rem;
    font-weight: 600;
}

@mixin event-sidebar-title {
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: .25px;
    margin-right: -0.25px;
    font-weight: 700;
}

@mixin preview-title {
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: .25px;
    margin-right: -0.25px;
    font-weight: 600;
}