@import "/src/ui/assets/styles/variables";

.ticket-order-detail-container {
    //width: max(50rem, 50%);
    margin: 0 auto;
    padding: $padding-3 0;
}


@media screen and (max-width: 48rem) {
    .ticket-order-detail-container {
        width: 100%;
        padding: 1.5rem 1rem;
        box-sizing: border-box;

        .ticket-order-detail {
            flex-direction: column;
        }
    }
}
