@import "/src/ui/assets/styles/variables.scss";

.event-banner-container {
    padding: $padding-5 0;
    border-radius: $border-radius-3;
    background-color: rgba(255, 255, 255, 0.85);
    opacity: 0.9;
    backdrop-filter: blur($blur-2);
    z-index: 10;
    display: flex;
    align-items: center;
    gap: $gap-3;

    .event-banner-info {
        display: flex;
        align-items: center;
        flex: 1;
        gap: $gap-3;

        .event-banner-avatar {
            width: 5rem;
            height: 5rem;
            object-fit: cover;
            border-radius: $border-radius-2;
        }

        .event-banner-title {
            display: flex;
            flex-direction: column;
            gap: $gap-1;

            .event-name {
                @include heading-3;
                margin-bottom: $margin-2;
            }

            .event-time,
            .event-address {
                @include text-3;
                color: $grey-color-2;
            }
        }
    }

    .event-banner-button {
        display: inline-flex;
        align-items: center;
        flex: 0 0 auto;
        gap: $gap-3;

        .event-banner-button-like,
        .event-banner-button-like:hover {
            border-color: $grey-color-3;
        }
    }
}

@media (max-width: 48rem) {
    .event-banner-container {
        //width: 100%;
        margin: 0 auto;
        padding: $padding-4;

        .event-banner-info {
            display: flex;
            align-items: center;
            flex: 1;
            gap: $gap-1;

            .event-banner-avatar {
                width: 4rem;
                height: 4rem;
                object-fit: cover;
                border-radius: $border-radius-2;
            }

            .event-banner-title {
                display: flex;
                flex-direction: column;
                gap: $gap-1;

                .event-name {
                    @include text-4;
                    font-weight: 500;
                    margin-bottom: $margin-2;
                }

                .event-tag-list {
                    display: none;
                }

            }
        }
    }
}