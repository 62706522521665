@import '/src/ui/assets/styles/variables';

.search-generics-container {
    height: 100%;
    max-width: 80rem;
    margin: $margin-5 auto;

    .search-generics-header {
        margin-bottom: $margin-3;

        .search-generics-title {
            @include title-2;
            margin-bottom: $margin-4;

            span {
                color: $primary-color;
                font-style: italic;
            }
        }

        .search-generics-button-list {
            display: flex;
            align-items: center;
            gap: $gap-1;

            .ant-select-selection-placeholder {
                color: $secondary-color;
            }
        }
    }

    .search-generics-main {
        display: flex;
        align-items: flex-start;
        gap: $gap-2;
        margin-bottom: $margin-6;

        .search-generics-list {
            flex: 3;
            display: flex;
            flex-direction: column;
            gap: $gap-4;

            .search-generics-other-events {
                .search-generics-other-events-title {
                    @include title-3;
                    color: $slate-color-1;
                    margin-top: $margin-4;
                    margin-bottom: $margin-3;
                }
            }
        }

        .search-generics-map {
            position: sticky;
            top: 5rem;
            flex: 2;
            padding: 0 $padding-2;

            .search-generics-activate-map {
                margin-bottom: $margin-2;
                width: 100%;
                height: 8rem;
                border-radius: $border-radius-3;
                overflow: hidden;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    filter: blur(3px);
                }

                .search-generics-activate-map-btn {
                    z-index: 10;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    display: flex;
                    gap: $gap-1;
                    align-items: center;
                }
            }
        }
    }
}

@media screen and (max-width: 48rem) {
    .search-generics-container {
        padding: $padding-3;
        height: 100%;

        .search-generics-header {
            .search-generics-button-list {
                gap: 0.5rem;
                flex-wrap: wrap;
                justify-content: flex-end;

                .search-generics-filter-time,
                .search-generics-filter-format,
                .search-generics-filter-price,
                .search-generics-filter-category {
                    width: 100% !important;

                    .ant-select {
                        min-width: unset !important;
                        width: 100%;
                    }
                }
            }
        }

        .search-generics-main {
            height: 100%;

            .search-generics-map {
                display: none;
            }
        }
    }
}

.filter-date-item {
    padding: $padding-1 $padding-8;
}