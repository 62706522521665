@import '/src/ui/assets/styles/variables.scss';


.onboarding-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .onboarding-head {
        width: max(30%, 30rem);
        display: flex;
        justify-content: center;

        @include title-1;
        color: $secondary-color-tint-2;
        text-align: center;
        margin: $margin-4 0;
    }
}