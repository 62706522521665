@import "/src/ui/assets/styles/variables.scss";

.org-checkin-container {
    //width: 95%;
    margin: 0 auto;
    padding: $margin-6 $padding-3 $padding-3 $padding-3;

    .org-checkin-head {
        //width: 56rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $margin-3;

        .org-checkin-title {
            @include title-2;
            color: $slate-color-2;
        }
    }

    .org-checkin-content {
        display: flex;
        align-items: flex-start;
        gap: $gap-5;

        .org-checkin-list {
            flex: 2;

            .org-checkin-search-input {
                margin-bottom: $margin-3;
            }
        }

        .org-checkin-scanner {
            flex: 1;
        }
    }
}