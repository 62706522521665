@import "src/ui/assets/styles/variables.scss";

.ils-bottom-bar-container {
    padding: 0 $padding-3;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ils-bottom-bar-main-buttons,
    .ils-bottom-bar-extend-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $gap-2;
    }
}