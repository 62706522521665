@import "./ui/assets/styles/reset.scss";
@import "./ui/assets/styles/font.scss";
@import "./ui/assets/styles/spacing.scss";
@import "./ui/assets/styles/color.scss";
@import "./ui/assets/styles/border.scss";
@import '/src/ui/assets/styles/typography.scss';

body,
html,
#root {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: "Inter", sans-serif !important;
}

.ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}


.ant-btn-default {
    border: none;

    &:hover {
        background: $slate-color-5;
        border: none;
    }

}

.highlight-button {
    background: $light-background-white-1;
    color: $primary-color !important;

    &:hover {
        background: $light-background-white-3;
        color: $primary-color !important;
    }
}

.ant-modal-footer {
    display: flex;
    align-items: center;
    gap: $gap-1;

    & > * {
        flex: 1;
    }
}

.ant-upload-list-item-actions {
    display: flex;
    align-items: center;
    justify-content: center;
}

::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5rem;
}

::-webkit-scrollbar-thumb {
    background-color: $slate-color-5;
    border-radius: $border-radius-2;
}

.ant-input-number {
    width: 100%;
}

.toast-global-style {
    padding: $padding-3;
    @include text-2;
}

.ant-picker-time-panel-cell-selected {
    .ant-picker-time-panel-cell-inner {
        background: $light-background-white-1 !important;
    }
}