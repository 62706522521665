@import '/src/ui/assets/styles/variables.scss';

.preview-card-container {
    display: flex;
    padding: $padding-3;
    gap: $gap-3;
    box-sizing: border-box;
    margin: 0 auto;
    background: $white;
    width: 100%;
    border-radius: $border-radius-2;
    box-shadow: $box-shadow-5;
    @include text-1;

    .preview-card-image {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        width: 14rem;
        height: 8rem;
        border-radius: $border-radius-2;
        background-color: $slate-color-6;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .preview-event-details {
        display: flex;
        flex-direction: column;
        gap: $gap-1;
        width: 100%;

        .event-preview-title {
            @include preview-title;
        }

        .event-preview-start-date {
            @include text-6;
        }

        .event-address {
            @include text-2;
        }

        .preview-footer {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;
            align-items: center;

            .event-preview-ticket-info {
                display: flex;
                gap: $margin-3;

                .event-preview-ticket-price,
                .event-preview-ticket-slot {
                    display: flex;
                    align-items: center;
                    gap: $gap-6;
                    @include text-6;
                    font-weight: normal;
                }
            }

            .event-preview-link {
                .preview-link {
                    color: $link-color;
                }
            }
        }
    }
}