@import "/src/ui/assets/styles/variables";

.ticket-rsv-container {
	width: max(60%, 70rem);
	margin: 0 auto;

	.ticket-rsv-head {
		margin-bottom: $margin-3;
	}
}

@media screen and (max-width: 48rem) {
	.ticket-rsv-container {
		width: 100%;
		.ticket-rsv-head {
            .event-banner-container {
				display: none;
            }

			.ticket-steppers-container {
				display: none;
			}
		}

		.ticket-rsv-content {
            .ticket-order-info-container {
                display: block;
	            .ticket-order-info-content {

	            }
            }

			.ticket-buyer-info-container {
				.ticket-buyer-info-head {

				}

				.ticket-buyer-info-form-container {
					padding: $padding-3;

					.ant-form.ant-form-vertical {
						width: 100%;
					}
				}
			}
		}

		.ticket-rsv-navigation {
			.ticket-cart {
				display: flex;
				justify-content: center;
				padding: $padding-3;
			}

			.ticket-cart-button {
				margin-bottom: 5rem;
				padding: 0 $padding-3;
				box-sizing: border-box;
			}
		}
	}
}


