@import '/src/ui/assets/styles/variables';

.highlightContainer {
    display: flex;
    gap: 1.69rem;
    flex-direction: column;
    
    .iconHolder {
        display: flex;
        gap: 0.93rem;
        align-items: center;
        .icon {
            width: 2.625rem;
            height: 2.3625rem;
        }
        .logoName {
            color: $white;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.25rem;
        }
    }

    .description {
        .descriptionText {
            color: $white;
            font-size: 1rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }

    .hightlightList {
        display: flex;
        gap: 1.69rem;
        flex-direction: column;
    }

    .discoverMore {
        cursor: pointer;
        
        .discoverMoreText{
            color: $white;
            text-align: center;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.25rem; 
        }    
    }
}