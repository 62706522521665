@import "/src/ui/assets/styles/variables.scss";


.menu-bottom-container {
	background: white;
	backdrop-filter: blur(5px);
	background: rgba(245, 245, 245, 0.9);
	z-index: 1000;
	padding: 1rem 2rem;
	display: none;

	a.active {
		svg {
			fill: $black;
		}
	}
}

@media screen and (max-width: 48rem) {
	.menu-bottom-container {
		display: flex;
		justify-content: space-between;
	}

}