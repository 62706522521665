@import '/src/ui/assets/styles/variables.scss';

.vertical-event-list-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $gap-4;
}

@media screen and (max-width: 48rem) {
    .vertical-event-list-container {
        gap: $gap-2;
    }
}
