@import "/src/ui/assets/styles/variables";

.profile-stat-item {
    display: flex;
    gap: $gap-1;
    align-items: center;
    justify-content: center;

    .profile-stat-item-value {
        @include title-3;
    }

    .profile-stat-item-label {
        @include text-2;
        color: $slate-color-3;
    }
}