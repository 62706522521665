@import '/src/ui/assets/styles/variables.scss';


.publish-page-container {
    padding: $padding-7 $padding-5;
    box-sizing: border-box;
    width: max(80%, 60rem);
    margin: 0 auto;

    .publish-page-header {
        display: flex;
        gap: $gap-2;
        flex-direction: column;
        margin-bottom: $margin-5;

        .publish-page-heading {
            @include heading-2;
        }

        .publish-page-description {
            @include text-1;
        }
    }

    .publish-page-settings {
        margin-top: $margin-5;

        .publish-page-setting-heading {
            .setting-heading {
                @include heading-3;
            }
        }

        .publish-time-option-container {
            margin-top: $margin-5;

            .publish-time-option-description {
                @include text-1;
                margin-bottom: $margin-4;
            }

            .publish-time-option {
                display: flex;
                flex-direction: column;

                .date-time-picker {
                    margin-top: $margin-3;
                }
            }
        }
    }

    .action-handler {
        position: fixed;
        bottom: 0;
        right: 0;
        padding: $padding-5;
        display: flex;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: end;
        justify-content: flex-end;
        box-sizing: border-box;
        background: $white;
        gap: $gap-2;

        .publish-button {
            padding: $padding-5;
        }
    }
}