@import '/src/ui/assets/styles/variables.scss';


.feedback-page {
    padding: $margin-6 $padding-3 $padding-3 $padding-3;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    .feedback-container {
        display: flex;
        flex-direction: column;
        gap: $gap-2;
        width: max(80%, 60rem);

        .feedback-header {
            .feedback-heading {
                @include heading-1;
            }
        }

        .feedback-link {
            .feedback-link-title {
                margin-bottom: $margin-2;
            }
        }
    }
}