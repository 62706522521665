@import "../../assets/styles/variables";

.host-online-event-container {
    height: 100%;
    width: min(90%, 70rem);
    margin: 0 auto;
    padding: $padding-5 0;

    .host-online-event-title {
        @include heading-2;
        color: $slate-color-3;
        margin-bottom: $margin-4;
    }

    .host-online-event-content {
        .host-online-event-external {
            .host-online-event-head {
                margin: $margin-3 auto;
                display: flex;
                flex-direction: column;
                gap: $gap-3;

                .host-online-event-external-message {
                    @include text-3;
                    color: $slate-color-4;
                }
            }

            .host-event-external-content {
                display: flex;
                align-items: center;
                gap: $gap-4;

                .host-provider-logo {
                    width: 2.5rem;
                    height: 2.5rem;
                    padding: $padding-3;
                    border: $border-width-1 solid $slate-color-5;
                    border-radius: $border-radius-2;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .host-event-locator {
                    padding: $padding-8;
                    border: $border-width-1 solid $slate-color-5;
                    border-radius: $border-radius-2;
                    margin-bottom: $margin-2;
                }
            }
        }

        .host-online-event-by-eventure {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: $gap-5;

            .host-online-event-detail {
                display: flex;
                justify-content: center;

                .host-online-event-detail-content {
                    width: 20rem;
                }
            }
        }
    }
}