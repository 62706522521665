@import "src/ui/assets/styles/variables.scss";

.participant-grid-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .participant-grid-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .participant-grid-item-list {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;

            .participant-grid-item {
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                overflow: clip;
                padding: $padding-1;
            }
        }
    }
}