@import "src/ui/assets/styles/variables.scss";

.participant-panel-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    background-color: $slate-color-dark-2;

    .participant-panel-list-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1 1 0;

        .participant-panel-list-item {
            margin: $margin-2;
            padding: $padding-3;
            background-color: $slate-color-dark-1;
            border-radius: $border-radius-2;

            display: flex;
            align-items: center;
            justify-content: center;

            .participant-item-avatar {
                height: 2.5rem;
                width: 2.5rem;
                @include text-3;
                border-radius: $border-radius-2;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $slate-color-dark-2;
                background-color: $slate-color-1;
            }

            .participant-item-name {
                flex: 1;
                margin-left: $margin-2;
                display: flex;
                overflow: hidden;
                white-space: pre-wrap;
                text-overflow: ellipsis;
                color: $slate-color-6;;
            }

            .participant-item-mic,
            .participant-item-cam {
                margin: $margin-1;
                padding: $padding-1;
                color: $slate-color-6;;
            }
        }
    }
}