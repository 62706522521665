@import '/src/ui/assets/styles/variables';


.profile-preference-container {
    .user-interest-tag {
        margin: $margin-4 0 $margin-5;
        padding: $padding-3 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: $gap-2;

        &.align-right-tags {
            justify-content: flex-start;
        }
    }

    .profile-preference-button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $gap-3;
    }
}