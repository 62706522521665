@import '/src/ui/assets/styles/variables.scss';


.preview-event-detail-container {
	background: $slate-color-6;
	border-radius: $border-radius-2;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: $padding-5;
	box-sizing: border-box;

	.preview-organization {
		margin-top: $margin-5;

		.preview-organization-text {
			strong {
				font-weight: 700;
			}
		}
	}
}
