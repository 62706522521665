@import "/src/ui/assets/styles/variables.scss";

.event-form-container {
	display: flex;
	justify-content: center;
	.content-container {
		height: auto;
		width: 100%;
		padding: $padding-6;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		gap: $gap-5;
		max-width: 60rem;
		margin-bottom: $margin-7;
	
		.event-details-form {
			width: 100%;
			display: flex;
			flex-direction: column;
	
			.event-details-title {
				@include text-1;
			}
		}
	
		.buttons-holder {
			display: flex;
			justify-content: flex-end;
			flex-direction: row-reverse;
			gap: $padding-3;

			.ant-form-item {
				margin-bottom: 0;

				.create-button {
					padding: $padding-5;
				}
			}

			.cancel-button {
				padding: $padding-5;
			}
		}
	}
}


