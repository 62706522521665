@import "/src/ui/assets/styles/variables.scss";

.main-layout-container {
    @include layout-container();
}

.no-sidebar {
    @include layout-container(false);
}

.main-sidebar {
    grid-area: sidebar;
    background-color: $white;
    position: sticky;
    top: 4rem;
    left: 0;
}

.main-display {
    grid-area: main;

    &.message-page {
        overflow: hidden;
        border-top: 1px solid #eaeaea;
    }
}

.main-header {
    grid-area: header;
    position: sticky;
    top: 0;
    background-color: $light-background-transparent-1;
    backdrop-filter: blur($blur-4);
    z-index: 100;
}

.main-footer {
    grid-area: footer;
    margin-bottom: $margin-3;
}

.bottom-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}


@media screen and (max-width: 48rem) {
    .main-layout-container {
        .bottom-menu {
            &.on-chat-page {
                display: none;
            }
        }
    }
}