@import "/src/ui/assets/styles/variables.scss";

.create-event-tags-container {
    .create-event-tags-content {
        width: 100%;
        margin-bottom: $margin-3;

        .create-event-tags-content {
            @include text-2;
            color: $slate-color-2;
            margin-bottom: $margin-2;
        }

        .create-event-tags {
            display: flex;
            flex-wrap: wrap;
            gap: $gap-2;

            .create-event-tag-input {
                width: 8rem;
                height: 2rem;
                @include text-2;
                font-weight: 400;
                margin-inline-end: 1rem;
                vertical-align: center;
            }

            .create-event-tag-item {
                height: 2rem;
                width: fit-content;
                background-color: $slate-color-6;
                @include text-2;
                font-weight: 400;
                display: flex;
                align-items: center;
                gap: $gap-1;

                span {
                    display: flex;
                    align-items: center;
                    vertical-align: center;
                }

                &.tag-plus {
                    color: $slate-color-4;
                    font-weight: 300;
                    border-style: dashed;
                    background-color: $white;
                }
            }
        }
    }
}