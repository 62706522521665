@import '/src/ui/assets/styles/variables.scss';

.category-tag-container {
    border-radius: $border-radius-2;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $gap-1;
    border: $border-width-2 solid $grey-color-4;
    padding: $padding-1 $padding-2;
    cursor: pointer;
    width: 9rem;

    .tag-icon {
        color: $grey-color-3;
    }

    .tag-title {
        @include text-1;
        font-weight: 400;
        color: $grey-color-2;
    }

    &:hover {
        background-color: $grey-color-5;

        .tag-title {
            color: $primary-color-hover;
        }
    }

    &.active {
        border-color: $light-background-white-1;
        background-color: $light-background-white-1;

        & > * {
            color: $primary-color;
        }
    }


}