@import "/src/ui/assets/styles/variables";

.select-province-district-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: $gap-3;

    & > * {
        flex: 1;
    }
}