@import "/src/ui/assets/styles/variables";

.organizer-profile-header-container {

    .organizer-profile-media {
        margin-bottom: $margin-4;
        position: relative;

        .organizer-profile-cover-img {
            width: 100%;
            height: 24rem;
            border-radius: $border-radius-3;
            overflow: hidden;

            &.no-cover-img {
                background: linear-gradient(
                                220deg,
                                rgb(241, 243, 244) 0%,
                                rgb(225, 234, 239) 34%,
                                rgb(241, 244, 247) 60%,
                                rgb(245, 253, 255) 100%
                );
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .organizer-profile-follow {
            position: absolute;
            bottom: -3rem;
            padding: 0 $padding-4;
            display: flex;
            align-items: flex-end;
            gap: $gap-4;

            .organizer-profile-avatar-img {
                width: 7rem;
                height: 7rem;
                border-radius: $border-radius-3;
                border: $border-width-6 solid $slate-color-6;
                overflow: hidden;
                background-color: $white;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .organizer-profile-button {
                padding-bottom: $padding-1;
                display: flex;
                gap: $gap-2;
            }
        }
    }

    .organizer-profile-info {
        padding: $padding-6 $padding-4 $padding-3;

        .organizer-profile-name {
            @include title-1;
            color: $grey-color-1;
            margin-bottom: $margin-1;
        }

        .organizer-profile-intro {
            @include text-2;
            color: $grey-color-2;
        }
    }

    .organizer-profile-stat {
        padding: $padding-3 $padding-4;
        display: flex;
        align-items: center;
        gap: $gap-2;

        .organizer-profile-stat-item {
            display: flex;
            align-items: center;
            gap: $gap-1;

            .organizer-profile-stat-label {
                @include text-3;
                color: $slate-color-3;
            }

            .organizer-profile-stat-value {
                @include text-3;
                color: $slate-color-2;
            }
        }
    }

    .organizer-profile-about {
        padding: $padding-3 $padding-4;

        .organizer-profile-about-title {
            @include title-3;
            color: $slate-color-3;
            margin-bottom: $margin-3;
        }

        .organizer-profile-about-content {
            @include text-2;
            color: $slate-color-1;
        }
    }

    .organizer-profile-social {
        display: flex;
        align-items: center;
        gap: $gap-2;
        padding: 0 $padding-4;

        .organizer-profile-social-link {
            color: $grey-color-2;

            &:hover {
                color: $primary-color;
            }
        }
    }
}