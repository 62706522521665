$border-radius-1: 0.25rem;
$border-radius-2: 0.5rem;
$border-radius-3: 1rem;
$border-radius-4: 1.5rem;
$border-radius-5: 2rem;
$border-radius-6: 0.75rem;
$border-radius-rounded: 50%;

$border-width-1: 0.5px;
$border-width-2: 1px;
$border-width-3: 2px;
$border-width-4: 3px;
$border-width-5: 4px;
$border-width-6: 6px;