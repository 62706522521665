@import '/src/ui/assets/styles/variables.scss';

.event-recording-page-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $gap-3;
    padding: $padding-7;
    box-sizing: border-box;

    .event-recording-page-title {
        @include title-2;
        color: $slate-color-3;
    }

    .event-recording-save {
        .event-recording-save-message {
            @include text-2;
            color: $secondary-color;
            margin-bottom: $margin-2;
        }
    }

    .event-recording-page-content {
        display: flex;
        flex-direction: column;
        gap: $gap-5;
        margin-bottom: $margin-3;
    }

    .event-recording-empty-message {
        @include heading-1;
        text-align: center;
        color: $slate-color-5;
        margin: $margin-4 auto;
    }

    .recording-input {
        width: 34rem;
    }

    .event-recording-videos {
        display: flex;
        align-items: center;
        gap: $gap-3;

        .event-recording-internal,
        .event-recording-external {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: $gap-2;
            height: 24rem;
            padding: $padding-4;
            border: $border-width-1 solid $slate-color-4;
            border-radius: $border-radius-2;
            cursor: pointer;

            &.disable {
                pointer-events: none;
                cursor: not-allowed !important;
            }

            &.selected {
                border-color: $primary-color;
                background: $light-background-white-3;
            }

            .recording-internal-video,
            .recording-external-video {
                width: 32rem;
                height: 20rem;
                background: $slate-color-6;
                border-radius: $border-radius-3;

                .recording-blank-message {
                    @include title-2;
                    color: $slate-color-3;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }

                div:first-child {
                    width: 100% !important;
                    //aspect-ratio: 16/9 !important;
                    height: 100% !important;
                    border-radius: $border-radius-3;
                    overflow: hidden;
                }
            }
        }
    }
}