@import '/src/ui/assets/styles/variables.scss';

.featured-event-container {
    height: 33rem;
    border-radius: $border-radius-3;
    overflow: hidden;

    .featured-event-item {
        height: 33rem;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

.ant-carousel {
    position: relative;

    .slick-arrow {
        &.slick-prev,
        &.slick-next {
            @include text-1;
        }
    }

    .slick-prev,
    .slick-next {
        @include text-1;
        color: $secondary-color !important;
        position: absolute;
        z-index: 10;
        background-color: $light-background-transparent-1;
        height: 1rem;
        width: 1rem;
        border-radius: $border-radius-rounded;
        display: flex;
        padding: $padding-3;

        &:hover, &:focus, &::before {
            color: $primary-color !important;
            background-color: $light-background-transparent-1;
        }
    }

    .slick-next {
        right: 1rem;
    }

    .slick-prev {
        left: 1rem;
    }

}

@media screen and (max-width: 48rem) {
    .featured-event-container {
        height: 10rem;

        .featured-event-item {
            height: 10rem;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .ant-carousel {
            height: 100%;

            .slick-arrow {
                &.slick-prev,
                &.slick-next {
                    @include text-1;
                    height: 0;
                    width: 0;
                    display: none;
                    background: none;
                }
            }

            .slick-prev,
            .slick-next {
                @include text-1;
                height: 0;
                width: 0;
            }

            .slick-slider {
                height: 100%;
            }
        }
    }
}
