@import '/src/ui/assets/styles/variables.scss';


.tools-container {
	display: flex;
	justify-content: space-between;
	margin: $margin-4 0 $margin-4 0;

	.add-button {
		padding: $padding-4;
	}
}