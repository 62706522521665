@import "/src/ui/assets/styles/variables.scss";

.social-share-container {
    display: flex;
    flex-direction: column;
    gap: $gap-1;

    .social-share-section-title {
        @include text-2;
        color: $slate-color-3;
        margin-bottom: $margin-3;
    }

    .social-share-url-content {
        width: 100%;
        height: 3rem;
        display: flex;
        align-items: center;
        gap: $gap-1;

        .social-share-url-bar {
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $slate-color-6;
            border-radius: $border-radius-2;

            .social-share-url-text {
                @include text-1;
                color: $secondary-color;

                &:hover {
                    color: $primary-color-hover;
                }
            }
        }

        .social-share-url-button {
            height: 100%;
            flex-basis: 7rem;
        }
    }

    .social-share-button-list {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: $gap-2;
    }
}