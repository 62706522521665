@import '/src/ui/assets/styles/variables.scss';

.event-organizer-container {

    display: flex;
    flex-direction: row;
    gap: $gap-3;
    align-items: center;
    height: 6rem;

    .event-organizer-avatar {
        width: 6rem;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: $border-radius-2;
        }
    }

    .event-organizer-info {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .org-name {
            @include title-3;
            color: $secondary-color;

            &:hover {
                color: $secondary-color-hover;
            }
        }

        .org-slogan {
            @include text-2;
            color: $grey-color-2;
        }

        .org-email {
            @include text-3;
            color: $grey-color-1;
        }

        .org-info-btn {
            display: flex;
            flex-direction: row;
            gap: $gap-2;
            align-items: center;

            .org-info-btn-view {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
    }
}