@import "../../../../assets/styles/variables";

.device-preview-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    .device-preview-content {
        width: 50rem;
        height: 45vh;
        background-color: #1c1c1c;
        border-radius: 1rem;
        overflow: hidden;
        position: relative;

        .device-preview-video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .device-preview-message {
            color: $slate-color-6;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .device-preview-button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
    }
}