@import '/src/ui/assets/styles/variables.scss';

.event-desc-container {
    margin-top: $margin-4;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $gap-5;

    @container (max-width: 48rem) {
        gap: $gap-2;
    }

    .event-desc-section {
        .event-desc-section-detail {
            margin-top: $margin-2;
        }
    }

    .event-desc-section-ticket {
        .section-detail-ticket {
            display: flex;
            flex-direction: column;
            gap: $gap-2;
        }
    }
}