@import '/src/ui/assets/styles/variables.scss';

.event-sidebar {
    height: 100vh;
    width: 18rem;
    background-color: $slate-color-7;
    position: relative;
    z-index: 3;

    .event-sidebar-desc {
        .event-description {
            .event-organizations {
                .event-description-link {
                    padding: $padding-3;
                    border-bottom: $border-width-1 solid $slate-color-5;
                    display: flex;
                    align-items: center;
                    color: $secondary-color;

                    &:hover {
                        color: $primary-color;
                    }
                }
            }

            .event-status-dropdown {
                margin: 1rem 0 0 1.25rem;
            }

            .event-description__title {
                padding: $padding-3 $padding-5 $padding-5 $padding-5;
                border-bottom: $border-width-1 solid $slate-color-5;

                .title-holder {
                    @include title-2;

                    .event-nav-description-title {
                        color: $primary-color;

                        &:hover {
                            color: $primary-color-hover;
                        }
                    }
                }

                .event-date-time {
                    padding-top: 0.5rem;
                    font-size: .875rem;
                    line-height: 1.25rem;
                    display: flex;
                    flex-direction: column;
                    gap: $gap-1;

                    strong {
                        font-weight: 700;
                    }
                }

                .event-description__preview {
                    margin-top: 0.5rem;

                    .preview-link {
                        color: $link-color;
                        font-size: .875rem;
                        line-height: 1.25rem;
                    }
                }
            }
        }
    }
}