@import '/src/ui/assets/styles/variables.scss';


.org-profile-avatar-container {

    .org-profile-avatar-content {
        display: flex;
        align-items: center;
        gap: $gap-2;

        .org-profile-avatar-img {
            width: 10rem;
            height: 10rem;
            border-radius: $border-radius-2;
            overflow: hidden;

            .ant-upload-wrapper {
                height: 100%;

                .ant-upload.ant-upload-select {
                    width: 100%;
                    height: 100%;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}