@import "src/ui/assets/styles/variables.scss";

.hls-container {
    width: 100%;
    height: 100%;
    border-radius: $border-radius-1;
    position: relative;
    overflow: hidden;
    background-color: $slate-color-dark-2;

    .presenter-view-video {
        position: absolute;
        height: 100%;
        width: 100%;

        video {
            object-fit: contain;
            overflow: hidden
        }
    }
}