@import '/src/ui/assets/styles/variables.scss';


.event-detail-container {
    margin: 0 auto;
    width: 70rem;
    container-type: inline-size;

    @container (max-width: 48rem) {
        width: 100%;
    }

    .event-detail-content {

        .event-content-container {
            margin-top: $margin-3;
            margin-bottom: $margin-4;
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: $gap-5;
            align-items: flex-start;

            .event-content-right {
                width: 75%;

                @container (max-width: 48rem) {
                    width: 100%;

                    .image-gallery-image {
                        height: 20rem;
                    }
                }
            }

            @container (max-width: 48rem) {
                width: 100%;
                display: block;
                padding: $padding-3;
                box-sizing: border-box;
            }

            .event-content-left {
                position: sticky;
                top: 5rem;
                width: 25%;

                @container (max-width: 48rem) {
                    width: 100%;
                    margin-top: $margin-5;
                    background-color: $white;
                }
            }
        }
    }

    .event-detail-related-events {
        margin: $margin-4 0;
        border-top: $border-width-1 solid $grey-color-5;
        padding: $padding-4 0;

        .related-events-header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: $margin-3;

            .related-events-title {
                @include title-3;
                color: $grey-color-1;
            }

            .related-events-see-more-btn {
                @include text-2;
                color: $grey-color-2;
                display: flex;
                align-items: center;

                &:hover {
                    color: $secondary-color;
                }
            }
        }
    }
}

@media (max-width: 48rem) {
    .event-detail-container {
        width: 100%;
    }
}