@import "/src/ui/assets/styles/variables";

.organizer-profile-content-container {
    border-top: $border-width-1 solid $slate-color-5;
    margin-top: $margin-4;
    padding: $padding-4 $padding-4 0;

    .organizer-profile-event-list-head {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $margin-3;

        .organizer-profile-event-title {
            @include title-3;
            color: $grey-color-2;
        }

        .list-option-segment {
            display: flex;
            gap: $gap-1;
            align-items: center;
        }
    }
}