@import '/src/ui/assets/styles/variables.scss';

.org-v-card-container {
    width: 100%;
    height: 8rem;
    padding: $padding-3 0;
    border-radius: $border-radius-3;
    border: $border-width-1 solid $slate-color-6;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        box-shadow: $box-shadow-1;
    }

    .org-v-card-avatar {
        padding: $padding-3;
        width: 8rem;
        height: 100%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: $border-radius-3;
        }
    }

    .org-v-card-content {
        flex: 1;
        height: 100%;
        padding: $padding-3;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        .org-v-card-head {
            .org-v-card-name {
                @include title-2;
                margin-bottom: $margin-1;
                color: $secondary-color;

                &:hover {
                    color: $primary-color;
                }
            }

            .org-v-card-bio {
                @include text-3;
                color: $slate-color-2;
            }
        }

        .org-v-card-bottom {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .org-v-card-statistic {
                flex: 1;
                display: flex;
                align-items: center;
                gap: $gap-3;

                .org-v-card-info {
                    display: flex;
                    align-items: center;
                    gap: $gap-1;
                }

                .org-v-card-info-label {
                    @include text-3;
                    color: $grey-color-2;
                }

                .org-v-card-info-value {
                    @include text-3;
                    color: $grey-color-1;
                }

                .org-v-card-follower {
                    flex: 1 1 3rem;
                }

                .org-v-card-event {
                    flex: 3 3 3rem;
                }
            }
        }
    }
}