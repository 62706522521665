@import '/src/ui/assets/styles/variables.scss';

.video-info-panel {
    width: 40rem;
    display: flex;
    flex-direction: column;
    gap: $gap-3;

    .video-info-section {
        .event-status-label {
            @include text-3;
            color: $slate-color-3;
            margin-bottom: $margin-2;
        }
    }

    .video-info-buttons {
        display: flex;
        gap: $gap-2;
    }
}