@import '/src/ui/assets/styles/variables.scss';

form {
    &#registration_form {
        width: 100%;

        label {
            @include label-1;
        }

        .register-form-button {
            padding: $padding-5;
        }
    }
}
