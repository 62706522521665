@import "/src/ui/assets/styles/variables";

.ticket-step-container {
    display: flex;
    align-items: center;
    gap: $gap-1;

    .ticket-step-order,
    .ticket-step-finish {
        width: 2rem;
        height: 2rem;
        border-radius: $border-radius-rounded;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ticket-step-finish {
        color: $primary-color;
        border: $border-width-1 solid $primary-color;
    }

    .ticket-step-order {
        color: $grey-color-2;
        border: $border-width-1 solid $grey-color-2;
    }

    .ticket-step-content {
        @include text-1;
        color: $grey-color-2;
    }

    &.active {
        .ticket-step-order {
            color: $white;
            background-color: $primary-color;
            border: none;
        }

        .ticket-step-content {
            border-radius: $border-radius-2;
            color: $primary-color;
        }
    }

    &.finish {
        .ticket-step-content {
            color: $primary-color-hover;
        }
    }
}