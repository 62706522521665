@import "/src/ui/assets/styles/variables";

.footer-container {
    width: max(60%, 80rem);
    margin: $margin-3 auto;

    .footer-main {
        padding: $padding-2 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .footer-main-left {
            display: flex;
            align-items: center;
            gap: $gap-2;

            .footer-main-logo {
                width: 1rem;
                height: auto;
            }

            .footer-main-sections {
                display: flex;
                align-items: center;
                gap: $gap-2;

                .footer-main-section-item {
                    @include text-2;
                    font-weight: 300;
                    color: $slate-color-4;

                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }

        .footer-main-right {
            display: flex;
            align-items: center;
            gap: $gap-3;

            .footer-main-social-icon {
                color: $slate-color-4;
                font-size: 2rem;
                cursor: pointer;

                &:hover {
                    color: $slate-color-1;
                }
            }
        }
    }

    .footer-bottom {
        border-top: $border-width-1 solid $slate-color-5;
        padding-top: $padding-3;
        margin-top: $margin-3;
        margin-bottom: $margin-5;
        @include text-2;
        font-weight: 300;
        color: $slate-color-3;
        text-align: center;
    }
}


@media screen and (max-width: 48rem) {
    .footer-container {
        display: none;
    }
}
