@import '/src/ui/assets/styles/variables.scss';

.scene-search-container {
    display: flex;
    flex-direction: column;
    gap: $gap-2;
    width: 100%;

    .scene-search-result {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: $gap-2;

        .scene-search-video-item {
            div {
                width: 14rem !important;
                height: unset !important;
                aspect-ratio: 16 / 9;
                border-radius: $border-radius-2;
                overflow: hidden;

                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .scene-search-video-timestamp {
            cursor: pointer;

            &:hover {
                color: $primary-color;
            }
        }
    }
}