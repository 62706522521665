@import "src/ui/assets/styles/variables.scss";

.presenter-view-container {
    width: 100%;
    height: 100%;
    border-radius: $border-radius-1;
    position: relative;
    overflow: hidden;

    .presenter-view-video {
        position: absolute;
        height: 100%;
        width: 100%;

        video {
            object-fit: contain;
            overflow: hidden
        }

        .presenter-view-label {
            position: absolute;
            bottom: 0.5rem;
            left: 0.5rem;
            background-color: $slate-color-dark-1;
            padding: $padding-2;
            border-radius: $border-radius-2;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .presenter-view-modal {
            border-radius: $border-radius-3;
            padding: $padding-7;
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: $slate-color-dark-2;
            transform: translate(-50%, -50%);

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: $gap-5;

            .presenter-view-modal-message {
                @include text-1;
                color: $slate-color-6;
            }
        }
    }
}