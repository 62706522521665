@import '/src/ui/assets/styles/variables.scss';

.report-event-submit-btn {
    background-color: $accent-color-1 !important;
}

.report-event-desc {
    @include text-2;
    color: $slate-color-2;
    margin: $margin-3 0;
}
