@import "/src/ui/assets/styles/variables";

.past-event-container {
    width: max(70%, 60rem);
    margin: 0 auto;

    .past-event-abnormal {
        .past-event-message {
            margin: $margin-5 auto;
            text-align: center;
            @include heading-3;
            color: $slate-color-4;
        }
    }
}