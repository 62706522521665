@import "/src/ui/assets/styles/variables";

.ticket-container {
    width: 20rem;
    margin: $margin-3 auto;
    display: flex;
    flex-direction: column;
    gap: $gap-2;
    padding: $padding-4;
    border-radius: $border-radius-3;
    border: $border-width-1 solid $slate-color-5;
    box-shadow: $box-shadow-1;
    background-color: $white;

    .ticket-name {
        padding-bottom: $padding-2;
        border-bottom: $border-width-1 dashed $slate-color-5;
        color: $primary-color;
        @include title-3;
    }

    .ticket-head {
        padding-bottom: $padding-2;
        border-bottom: $border-width-1 dashed $slate-color-5;

        .ticket-event-name {
            @include title-2;
            margin-bottom: $margin-3;
        }

        .ticket-event-time,
        .ticket-event-location {
            @include text-2;
            margin-bottom: $margin-2;
        }
    }

    .ticket-qr-code {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $white;
        padding-bottom: $padding-2;
        border-bottom: $border-width-1 dashed $slate-color-5;
    }

    .ticket-info {

        .ticket-info-item {
            margin-bottom: $margin-3;

            .ticket-info-label {
                @include text-4;
                color: $grey-color-2;
                margin-bottom: $margin-2;
            }

            .ticket-info-content {
                @include text-2;
                color: $secondary-color;
            }
        }
    }
}

@media screen and (max-width: 48rem) {
    .ticket-container {
        width: 16rem;

        .ticket-name {
            padding-bottom: $padding-2;
            @include text-3;
            font-weight: 500;
        }


        .ticket-head {

            .ticket-event-name {
                @include text-2;
            }

            .ticket-event-time,
            .ticket-event-location {
                @include text-3;
            }
        }
    }
}