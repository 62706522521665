@import '/src/ui/assets/styles/variables';

.ticket-drawer-contents {
	height: 100%;
	.ticket-drawer-form-container {
		height: 100%;
		position: relative;

		.ant-input-number {
			width: 100%;
		}

		.ticket-drawer-buttons {
			display: flex;
			gap: $gap-2;
			position: absolute;
			bottom: 0;
			width: 100%;

			.cancel-button-container,
			.save-button {
				flex: 1;
			}
		}
	}
}
