@import "src/ui/assets/styles/variables.scss";

.chat-panel-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .chat-panel-message-list {
        height: calc(100% - 5rem);
        padding: $padding-2;
        overflow-y: auto;
    }

    .chat-panel-input {
        min-height: 4rem;
        background-color: $slate-color-dark-2;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 $padding-2;
        display: flex;
        align-items: center;
    }
}