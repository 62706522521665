@import "/src/ui/assets/styles/variables.scss";

.main-menu-container {
    display: flex;
    flex-direction: column;
    gap: $gap-1;
    color: $grey-color-1;

    .menu-item {
        @include text-1;
        padding: $padding-2 $padding-2;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-radius: $border-radius-2;
        cursor: pointer;

        &:hover {
            background-color: $grey-color-5;
        }

        .menu-item-icon {
            width: 2rem;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &.active {
            color: $primary-color;
            background-color: $light-background-white-2;

            &:hover {
                background-color: $light-background-white-2;
            }
        }
    }
}
