@import '/src/ui/assets/styles/variables.scss';

.event-faq-container {
    .ant-collapse-item {
        margin-bottom: $margin-3 !important;
        border-bottom: $border-width-1 solid $slate-color-6 !important;
    }

    .ant-collapse-header,
    .ant-collapse-content-box {
        padding: $padding-2 0 !important;
    }

    .ant-collapse-header {
        @include text-2;
        color: $slate-color-1;

        &:hover {
            color: $primary-color-hover;
        }
    }

    .ant-collapse-content-box {
        @include text-2;
        font-weight: 400;
        color: $slate-color-2;
        line-height: 1.5;
    }
}