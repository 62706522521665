@import "/src/ui/assets/styles/variables.scss";

.create-event-online-container {
    width: min(50%, 50rem);
    margin: 0 auto;
    padding: $padding-5 0;

    .create-event-online-title {
        @include title-1;
        color: $grey-color-2;
        padding: $padding-3 0;
        margin-bottom: $margin-4;
    }

    .create-event-online-content {
        border: $border-width-1 solid $grey-color-4;
        border-radius: $border-radius-2;
        padding: $padding-3;

        .event-online-section {
            margin-bottom: $margin-4;

            .event-online-section-title {
                @include title-2;
                color: $secondary-color;
                margin-bottom: $margin-2;
            }

            .event-online-section-desc {
                display: flex;
                flex-direction: column;
                gap: $gap-2;
                margin-bottom: $margin-3;
            }
        }

        .event-online-provider {
            .event-online-provider-content {
                display: flex;
                align-items: center;
                gap: $gap-2;
            }
        }

        .event-online-information {
            .event-online-information-content {
                .event-online-eventure-room-url {
                    display: flex;
                    gap: $gap-2;
                }
            }
        }

        .create-event-online-preview {
            margin: $margin-5 0 0;
        }
    }

}

