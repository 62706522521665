@import "/src/ui/assets/styles/variables.scss";

.main-layout-org-container {
    @include layout-container-org();
}

.main-header-org {
    grid-area: header;
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 100;
}

.main-display-org {
    grid-area: main;

    &.manage-event-page {
        overflow-y: auto;
    }
}

.main-sidebar-org {
    grid-area: sidebar;
    background-color: $white;
    height: 100%;
    border-right: $border-width-1 solid $grey-color-4;
    position: relative;
    z-index: 3;

    //position: sticky;
    //top: 3.5rem;
    //left: 0;
}

