@import '/src/ui/assets/styles/variables.scss';

.user-past-events-container {
    width: 100%;

    .user-past-events-head {
        @include heading-3;
        margin-bottom: $margin-3;
    }
}

@media screen and (max-width: 48rem) {
    .user-past-events-container {
        .user-past-events-head {
            @include text-1;
            font-weight: 500;
        }
    }
}