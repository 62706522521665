@import "src/ui/assets/styles/variables.scss";

.corner-name-container {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    border-radius: $border-radius-3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $padding-3;

    background-color: $slate-color-dark-1;
    transition: all 200ms linear;
    transform: scale(0);

    &.show {
        transform: scale(1);
    }

    .corner-name-message {
        @include text-2;
        color: $slate-color-6;
        margin-left: $margin-1;
    }
}

.corner-name-tool {
    position: absolute;
    top: 1rem;
    right: 1rem;
    border-radius: $border-radius-2;
    padding: $padding-3;
    color: $primary-color-tint-1;
}