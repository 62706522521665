@import '/src/ui/assets/styles/variables.scss';

.sidebar-container {
    height: 100%;
    background-color: $white;
    color: $grey-color-2;
    @include text-2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .sidebar-menu {
        padding: $padding-2;
    }

    .sidebar-footer-container {
        padding: $padding-3;

        .footer-link {
            display: flex;
            flex-direction: column;
            gap: $gap-1;
            margin-bottom: $margin-3;

            a {
                @include text-5;
                color: $grey-color-2;
                text-decoration: none;
            }
        }

        .footer-company {
            @include text-5;
            color: $grey-color-2;
        }
    }
}