@import "/src/ui/assets/styles/variables.scss";

.agenda-item-container {
    padding: $padding-3;
    border-radius: $border-radius-2;
    background-color: $slate-color-8;

    .agenda-item-wrapper {
        border-left: $border-width-3 solid $primary-color;
        padding: 0 $padding-3;

        .agenda-item-time {
            @include text-3;
            color: $primary-color-hover;
            margin-bottom: $margin-2;
        }

        .agenda-item-title {
            @include title-3;
            color: $secondary-color;
        }

        .agenda-item-hosts {
            margin: $margin-3 0;
            display: flex;
            align-items: center;
            gap: $gap-2;

            .host-item {
                display: flex;
                align-items: center;
                gap: $gap-1;
                border-radius: $border-radius-2;
                width: fit-content;
                background-color: $white;
                padding: $padding-2;

                img {
                    border-radius: $border-radius-rounded;
                    width: 1.5rem;
                    height: 1.5rem;
                    object-fit: cover;
                }
            }
        }

        .agenda-item-description {
            border-top: $border-width-1 solid $slate-color-5;
            padding-top: $padding-2;
            @include text-3;
            color: $slate-color-2;
        }
    }
}