@import '/src/ui/assets/styles/variables.scss';

.header-search-input {
    width: 36rem;

    .header-search-main {
        flex: 2 2 16rem;
    }

    .header-search-location {
        flex: 1 1 14rem;
    }

    .header-search-button {
        border-start-start-radius: 0 !important;
        border-end-start-radius: 0 !important;
    }
}
