@import "/src/ui/assets/styles/variables.scss";

.notification-page-container {
    width: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: $margin-4 auto;


    .notification-header {
        margin: $margin-4;
        @include title-2;
        color: $slate-color-2;
    }
}


@media (max-width: 48rem) {
    .notification-page-container {
        width: 100%;
    }
}