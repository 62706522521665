$padding-1: 0.25rem;
$padding-2: 0.5rem;
$padding-3: 1rem;
$padding-4: 1.25rem;
$padding-5: 1.5rem;
$padding-6: 2rem;
$padding-7: 2.5rem;
$padding-8: 0.75rem;

$gap-1: 0.5rem;
$gap-2: 1rem;
$gap-3: 1.5rem;
$gap-4: 1.75rem;
$gap-5: 2rem;
$gap-6: 0.25rem;
$gap-7: 2.5rem;
$gap-8: 3rem;
$gap-9: 3.5rem;
$gap-10: 0.75rem;

$margin-1: 0.25rem;
$margin-2: 0.5rem;
$margin-3: 1rem;
$margin-4: 1.5rem;
$margin-5: 2rem;
$margin-6: 4rem;
$margin-7: 6rem;