@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 100;
    src:
            local("Inter Thin"),
            local("Inter-Thin"),
            url("../fonts/Inter/Inter-Thin.ttf") format("TrueType");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 200;
    src:
            local("Inter ExtraLight"),
            local("Inter-ExtraLight"),
            url("../fonts/Inter/Inter-ExtraLight.ttf") format("TrueType");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    src:
        local("Inter Light"),
        local("Inter-Light"),
        url("../fonts/Inter/Inter-Light.ttf") format("TrueType");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    src:
        local("Inter Regular"),
        local("Inter-Regular"),
        url("../fonts/Inter/Inter-Regular.ttf") format("TrueType");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    src:
        local("Inter Medium"),
        local("Inter-Medium"),
        url("../fonts/Inter/Inter-Medium.ttf") format("TrueType");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    src:
        local("Inter Semi Bold"),
        local("Inter-SemiBold"),
        url("../fonts/Inter/Inter-SemiBold.ttf") format("TrueType");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: bold;
    src:
        local("Inter Bold"),
        local("Inter-Bold"),
        url("../fonts/Inter/Inter-Bold.ttf") format("TrueType");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    src:
        local("Inter Extra Bold"),
        local("Inter-ExtraBold"),
        url("../fonts/Inter/Inter-ExtraBold.ttf") format("TrueType");
}


@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    src:
            local("Inter Black"),
            local("Inter-Black"),
            url("../fonts/Inter/Inter-Black.ttf") format("TrueType");
}