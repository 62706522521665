@import "/src/ui/assets/styles/variables.scss";

.ticket-item-container {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: $border-radius-3;
    border: $border-width-1 solid $slate-color-5;

    .ticket-item-info-label {
        @include text-5;
        font-weight: 300;
        color: $slate-color-3;
        margin-bottom: $margin-1;
    }

    .ticket-item-info-value {
        @include text-3;
        font-weight: 500;
        color: $secondary-color;
    }

    .ticket-item-left {
        padding: $padding-3;
        flex: 2 2 30rem;
        height: 8rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: $gap-2;
        border-right: $border-width-2 dashed $slate-color-5;

        .ticket-item-content-top {
            display: flex;
            flex-direction: column;

            .ticket-item-code {
                @include text-2;
                font-weight: 300;
                color: $primary-color;
                margin-bottom: $margin-1;
            }

            .ticket-item-ticket-name {
                @include title-3;
                font-weight: 600;
                color: $secondary-color;
            }
        }

        .ticket-item-content-bottom {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .ticket-item-event-name {
                @include title-4;
                margin-bottom: $margin-2;
            }

            .ticket-item-event-time,
            .ticket-item-event-location {
                @include text-4;
                margin-bottom: $margin-1;
                color: $secondary-color;
            }
        }
    }

    .ticket-item-right {
        padding: $padding-3;
        flex: 1 1 10rem;
        height: 8rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        .ticket-item-open-btn {
            display: none;
        }

        .ticket-item-admission {
            width: 100%;

            .ticket-item-price {
                @include text-1;
                font-weight: 500;
                margin-bottom: $margin-2;
            }
        }
    }

    &:hover {
        box-shadow: $box-shadow-1;

        .ticket-item-right {
            .ticket-item-open-btn {
                display: block;
            }
        }
    }
}

@media screen and (max-width: 48rem) {
    .ticket-item-container {
        .ticket-item-event-time,
        .ticket-item-event-location {
            display: none;
        }
    }
}