@import "/src/ui/assets/styles/variables";


.chat-container {
	display: flex;
	flex-direction: column;
	gap: $gap-1;

	.message-content {
		display: flex;
		justify-content: flex-end;

		.content {
			padding: $padding-2 $padding-3;
			border-radius: $border-radius-6;
			background: $primary-color-chat;
			@include text-3;
			color: $white;
		}
	}

	.message-content[aria-label=receiver] {
		display: flex;
		justify-content: flex-start;

		.content {
			background: $grey-color-5;
			color: $black;
		}
	}
}