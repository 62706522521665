@import "/src/ui/assets/styles/variables";

.user-profile-header-container {
    width: max(70rem, 50%);
    margin: 0 auto;
    height: 10rem;
    display: flex;
    align-items: center;
    gap: $gap-4;

    .profile-avatar {
        .profile-avatar-img {
            border-radius: $border-radius-rounded;
            background-color: $slate-color-2;
        }
    }

    .profile-content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .profile-info {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .profile-bio {
                margin-bottom: $margin-4;

                .profile-name {
                    @include title-2;
                    margin-bottom: $margin-2;
                }

                .profile-email {
                    @include text-3;
                    color: $slate-color-3;
                }
            }

            .profile-statistic {
                display: flex;
                align-items: center;
                gap: $gap-4;
            }
        }
    }
}
