@import '/src/ui/assets/styles/variables.scss';


.org-profile-cover-container {
    margin-bottom: $margin-4;
    width: 100%;
    height: 24rem;
    border-radius: $border-radius-2;
    overflow: hidden;

    .ant-upload-wrapper {
        height: 100%;

        .ant-upload.ant-upload-select {
            width: 100%;
            height: 100%;

            .ant-upload {
                .banner-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: $border-radius-6;
                }
            }
        }
    }


}