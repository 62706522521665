@import "/src/ui/assets/styles/variables";

.rsv-detail-container {
    width: max(50rem, 50%);
    margin: 0 auto;
    padding: $padding-5 0;

    .rsv-detail-header {
        margin-bottom: $margin-5;

        .rsv-detail-title {
            @include title-1;
            margin-bottom: $margin-2;

            .rsv-detail-event-name {
                color: $primary-color;
            }
        }

        .rsv-detail-btn {
            display: flex;
            align-items: center;
            gap: $gap-2;
        }
    }

    .rsv-detail {
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        gap: $gap-5;

        .rsv-detail-left {
            flex: 2;

            .rsv-info {
                //margin-bottom: $margin-4;

                .rsv-code {
                    display: flex;
                    gap: $gap-2;
                    align-items: center;
                    @include text-2;
                    font-weight: 600;
                    margin-bottom: $margin-1;
                }

                .rsv-datetime {
                    @include text-2;
                    color: $slate-color-3;
                    margin-bottom: $margin-2;
                }
            }
        }

        .rsv-detail-right {
            flex: 1;
            margin-bottom: $margin-6;

            .rsv-ticket-list-title {
                margin-bottom: $margin-4;
            }

            .rsv-ticket-list {
                display: flex;
                flex-direction: column;
                gap: $gap-3;
            }
        }
    }
}


@media screen and (max-width: 48rem) {
    .rsv-detail-container {
        width: 100%;
        padding: 1.5rem 1rem;
        box-sizing: border-box;

        .rsv-detail-header {
            .rsv-detail-title {
                @include text-1;
                font-weight: 500;
            }
        }

        .rsv-detail {
            flex-direction: column;
        }
    }
}
