@import "/src/ui/assets/styles/variables";


.message-page-container {
	height: 100%;
	width: 100%;
	display: flex;
}

.no-message-yet {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
}