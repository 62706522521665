@import '/src/ui/assets/styles/variables.scss';

.event-tags-container {
    display: flex;
    align-items: center;
    gap: $gap-2;
    flex-wrap: wrap;

    .tag-item {
        padding: $padding-2;
        background-color: $light-background-white-1;
        border-radius: $border-radius-1;
        @include text-2;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}