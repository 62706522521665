@import "/src/ui/assets/styles/variables";

.personal-container {
    width: 60%;
    margin: 0 auto;
    display: flex;
    gap: $gap-3;

    .personal-menu {
        width: 20%;
        padding: $padding-3;

        .personal-menu-title {
            @include heading-3;
            margin-bottom: $margin-2;
        }
    }

    .personal-section-content {
        width: 75%;
        padding: $padding-3;
    }
}
