@import "/src/ui/assets/styles/variables";


.message-panel-container {
	max-width: 22.5rem;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	flex: 1;
	padding: 0.75rem;
	border-right: 1px solid $grey-color-4;
}

@media screen and (max-width: 48rem) {
	.message-panel-container {
		max-width: 100%;

		&.hidden-mobile {
			display: none;
		}
	}



}