@import "/src/ui/assets/styles/variables.scss";

.no-data-container {
    width: 16rem;
    margin: 2rem auto;
    display: flex;
    gap: $gap-2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include text-1;
    font-weight: 300;
    color: $slate-color-5;
    text-align: center;

    span {
        color: $slate-color-4;
    }
}