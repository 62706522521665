@import '/src/ui/assets/styles/variables.scss';

.header-org-container {
    height: 100%;
    padding: 0 $padding-4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: $border-width-1 solid $grey-color-4;
    background-color: $white;

    .header-org-start,
    .header-org-end {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: $gap-2;
    }

    .header-org-start {
        .header-org-logo {
            width: 2rem;
            height: auto;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .header-org-end {
        .header-org-avatar {
            cursor: pointer;
        }
    }
}