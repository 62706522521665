@import "/src/ui/assets/styles/variables";

.voucher-item-container {
    border-radius: $border-radius-2;
    border: $border-width-1 solid $slate-color-6;
    display: flex;
    align-items: center;
    width: 28rem;
    height: 5rem;

    &.selected {
        border: $border-width-1 solid $primary-color;
        background-color: $light-background-white-2;
    }

    &.invalid {
        pointer-events: none;
        opacity: 0.4;

    }

    .voucher-item-first {
        flex-basis: 4rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $slate-color-9;
        border-right: $border-width-1 solid $slate-color-6;
    }

    .voucher-item-mid {
        flex: 1;
        padding: $padding-1 $padding-2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .voucher-item-off {
            @include title-3;
            color: $slate-color-1;
            margin-bottom: $margin-2;

            .voucher-item-condition {
                @include text-2;
                color: $slate-color-3;
            }
        }

        .voucher-item-code {
            @include text-4;
            color: $secondary-color;
            margin-bottom: $margin-1;

            span {
                color: $primary-color;
                font-weight: 500;
            }
        }

        .voucher-item-due {
            @include text-4;
            color: $secondary-color;
        }
    }

    .voucher-item-last {
        padding: $padding-2;

        .voucher-item-button {
            @include text-3;
            color: $slate-color-2;
        }
    }
}