@import "src/ui/assets/styles/variables.scss";

.not-found-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .not-found-image {
        width: 40%;
        height: auto;

        img {
            width: 100%;
            object-fit: contain;
        }
    }

    .not-found-text {
        margin-top: $margin-3;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $gap-3;

        .not-found-hero {
            @include heading-1;
            color: $grey-color-1;
        }

        .not-found-desc {
            @include text-1;
            color: $grey-color-2;
        }
    }
}