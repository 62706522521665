@import "/src/ui/assets/styles/variables";

.org-profile-section-head {
    margin-bottom: $margin-2;

    .org-profile-section-title {
        @include title-2;
        color: $secondary-color;
        margin-bottom: $margin-1;
    }

    .org-profile-section-description {
        @include text-2;
        color: $grey-color-1;
    }
}