@import '/src/ui/assets/styles/variables.scss';


.suggest-event-section-title {
    @include title-2;
    color: $slate-color-1;
    margin-bottom: $margin-3;
    display: flex;
    align-items: center;
    gap: $gap-1;

    img {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: contain;
    }
}

.suggest-event-list-container {
    .suggest-event-list-content {
        width: 100%;
    }
}