@import "/src/ui/assets/styles/variables";

.ticket-steppers-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $gap-3;

    .ticket-steppers-list {
        display: flex;
        align-items: center;
        gap: $gap-3;

        .ticket-steppers-divider {
            width: 5rem;
            border-radius: $border-radius-1;
            border: $border-width-1 solid $grey-color-3;

            &.finish {
                border: $border-width-1 solid $primary-color-tint-2;
            }
        }
    }
}