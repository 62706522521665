@import '/src/ui/assets/styles/variables.scss';

.horizontal-event-list-container {

    .event-list-grid {
        display: grid;
        gap: $gap-4;
        grid-template-columns: repeat(4, 1fr);
    }
}
