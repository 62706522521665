@import "/src/ui/assets/styles/variables";

.payment-successful-container {
    width: 100%;

    .ticket-finish-purchase-info {
        margin-top: $margin-4;

        .ticket-finish-purchase-info-title {
            @include title-3;
            color: $slate-color-1;
            margin-bottom: $margin-2;
        }

        .ticket-finish-purchase-info-list {
            margin-bottom: $margin-3;

            .purchase-info-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: $margin-1;

                .purchase-info-label {
                    @include text-2;
                    color: $slate-color-4;
                }

                .purchase-info-value {
                    @include text-2;
                    color: $secondary-color;
                }
            }
        }
    }

    .ticket-finish-note {
        width: 100%;
        margin-top: $margin-5;
        @include text-2;
        color: $slate-color-3;
        font-weight: 300;

        p {
            margin: $margin-2 0;
            @include text-3;

            &.highlight {
                color: $slate-color-1;
            }
        }
    }

}