@import "/src/ui/assets/styles/variables";

.ticket-order-info-container {
    display: flex;
    align-items: flex-start;
    gap: $gap-2;

    .ticket-order-info-content {
        flex: 2;
        display: flex;
        flex-direction: column;
        gap: $gap-2;
    }

    .ticket-rsv-navigation {
        flex: 1;

        .ticket-cart-button {
            margin-top: $margin-5;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: $gap-2;
            align-items: center;
            justify-content: space-between;
        }
    }
}