@import "/src/ui/assets/styles/variables.scss";

.notification-item-container {
    padding: $padding-2;
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: $gap-2;

    &:hover {
        background-color: $slate-color-7;
        border-radius: $border-radius-2;
    }

    .notification-icon-container {
        width: 4rem;
        height: 4rem;

        .notification-icon,
        .org-img {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: $border-radius-2;
            object-fit: cover;
        }
    }

    .notification-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: $gap-2;

        .notification-main-content {
            .notification-type {
                @include title-3;
                color: $primary-color-hover;
                margin-bottom: $margin-2;
            }

            .notification-content-text {
                font-size: 0.875rem;

                strong {
                    font-weight: 600;
                }
            }
        }

        .notification-time {
            display: flex;
            align-items: flex-start;
            height: 100%;
            @include text-4;
            font-weight: 400;
            color: $primary-color-hover;
        }
    }
}
