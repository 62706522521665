@import "/src/ui/assets/styles/variables";

.orders-page {
	display: flex;
	justify-content: center;
	padding: $margin-6 $padding-3 $padding-3 $padding-3;
	width: 100%;
	box-sizing: border-box;

	.orders-page-container {
		display: flex;
		gap: $gap-2;
		flex-direction: column;
		width: max(90%, 60rem);

		.orders-heading {
			@include heading-1;
		}
	}
}