@import "/src/ui/assets/styles/variables.scss";

.notification-main-list {
    overflow: auto;

    .ant-list-item {
        width: 100%;
    }

    .ant-skeleton {
        padding: $padding-3;

        .ant-skeleton-header {
            .ant-skeleton-avatar {
                width: 3.5rem;
                height: 3.5rem;
            }
        }
    }

    .loadmore-button-holder {
        display: flex;
        width: 100%;
        padding: $padding-3;
        justify-content: center;
    }
}

@media (max-width: 48rem) {
    .notification-main-list {
        width: 90%;
        margin: 0 auto;
    }
}