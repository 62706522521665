@import "/src/ui/assets/styles/variables.scss";

.rsv-item-container {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: $border-radius-3;
    border: $border-width-1 solid $slate-color-5;

    .rsv-item-info-label {
        @include text-5;
        font-weight: 300;
        color: $slate-color-3;
        margin-bottom: $margin-1;
    }

    .rsv-item-info-value {
        @include text-3;
        font-weight: 500;
        color: $secondary-color;
    }

    .rsv-item-left {
        padding: $padding-3;
        flex: 2 2 30rem;
        height: 8rem;
        display: flex;
        align-items: center;
        gap: $gap-2;
        border-right: $border-width-2 dashed $slate-color-5;

        .rsv-item-event-thumbnail {
            width: 10rem;
            height: 100%;
            border-radius: $border-radius-3;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .rsv-item-content {
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .rsv-item-content-top {
                .rsv-item-event-time {
                    @include text-4;
                    color: $secondary-color;
                }

                .rsv-item-event-name {
                    @include title-3;
                    font-weight: 600;
                    color: $secondary-color;
                    margin-bottom: $margin-1;

                    &:hover {
                        color: $primary-color;
                    }
                }
            }

            .rsv-item-time {
                @include text-3;
                font-weight: 500;
                color: $slate-color-3;

                .highlight {
                    color: $primary-color;
                }
            }

            .rsv-item-content-bottom {
                display: flex;
                align-items: center;
                gap: $gap-1;

                .rsv-item-event-location,
                .rsv-item-holder {
                    flex: 1;
                }
            }
        }
    }

    .rsv-item-right {
        padding: $padding-3;
        flex: 1 1 10rem;
        height: 8rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        .rsv-item-open-btn {
            //display: none;
        }

        .rsv-item-admission {
            width: 100%;


            .rsv-item-price {
                @include text-1;
                font-weight: 500;
            }
        }

        .rsv-item-total-ticket {
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }
    }

    &:hover {
        box-shadow: $box-shadow-1;

        .rsv-item-right {
            .rsv-item-open-btn {
                display: block;
            }
        }
    }
}

@media screen and (max-width: 48rem) {
    .rsv-item-container {
        height: unset;

        .rsv-item-left {
            height: unset;
            padding: $padding-2;
            flex: 2 2 20rem;
            display: flex;
            align-items: center;
            gap: $gap-1;
            border-right: $border-width-1 dashed $slate-color-5;

            .rsv-item-event-thumbnail {
                width: 3rem;
                height: 3rem;
                border-radius: $border-radius-2;
            }

            .rsv-item-content {
                height: unset;

                .rsv-item-time {
                    @include text-mobile-4;
                    font-weight: 500;
                    color: $slate-color-3;

                    .highlight {
                        color: $primary-color;
                    }
                }

                .rsv-item-content-top {
                    margin-bottom: $margin-2;

                    .rsv-item-event-time {
                        @include text-mobile-5;
                    }

                    .rsv-item-event-name {
                        @include text-4;
                        font-weight: 500;
                    }
                }

                .rsv-item-content-bottom {
                    display: none;
                }
            }
        }

        .rsv-item-right {
            padding: $padding-2;
            flex: 1 1 5rem;
            height: unset;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: $gap-2;

            .rsv-item-admission {
                width: 100%;

                .rsv-item-time {
                    @include text-mobile-4;
                    font-weight: 500;
                }

                .rsv-item-price {
                    @include text-mobile-4;
                    font-weight: 300;
                }
            }

            .rsv-item-total-ticket {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-start;
            }
        }
    }
}