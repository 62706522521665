@import '/src/ui/assets/styles/color.scss';
@import '/src/ui/assets/styles/spacing.scss';
@import '/src/ui/assets/styles/typography.scss';

.event-nav-item {

    &.active-event-nav-item {
        background: $light-background-white-1;

        .nav-list-item__text {
            color: $primary-color;
        }
    }

    .event-nav-list-item-link {
        padding: $padding-3 $padding-4;
        box-sizing: border-box;
        text-align: left;
        display: flex;

        .list-item-link-holder {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            .list-item-link__icon {
                margin-right: $margin-2;
            }

            .nav-item__contents {
                color: $secondary-color;
                text-rendering: optimizeLegibility;
                font-size: 0.875rem;
                font-weight: 600;
                line-height: 1.5;
            }

            &.expanded-link {
                width: 100%;
            }
        }


        &:hover {
            background-color: $slate-color-6;
        }
    }

    .event-nav-sub-list {
        .event-nav-list-container {
            .event-nav-list {
                .event-nav-item {
                    .event-nav-list-item-link {
                        padding-left: 2.25rem;
                    }
                }
            }
        }
    }
}