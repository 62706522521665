@import '/src/ui/assets/styles/variables.scss';

.event-footer-container {
	position: fixed;
	bottom: 0;
	right: 0;
	padding: $gap-3;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	box-sizing: border-box;
	background: white;
	gap: $gap-10;
	z-index: 2;
}

