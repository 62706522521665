@import '/src/ui/assets/styles/variables';

.highlightItemContainer {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    border-radius: 1rem;
    background: rgb(128, 129, 145, 0.2);

    .imageHolder {
        width: 6rem;
        height: 6rem;
        flex-shrink: 0;
        display: flex;
        gap: 0.63rem;
        overflow: hidden;
        border-radius: $border-radius-3 0 0 $border-radius-3;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .highlightText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 0.94rem;

        .highlightName {
            display: flex;
            height: 1.125rem;
            align-items: center;
            align-self: stretch;
            color: $white;
        }

        .highlightCategory {
            font-size: 0.8125rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem;
            color: $white;
            opacity: 0.6;
        }
    }
}