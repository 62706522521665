@import "/src/ui/assets/styles/variables.scss";

.v-card-container {
    max-width: 100%;
    height: 10rem;
    padding: $padding-3;
    display: flex;
    align-items: center;
    justify-content: stretch;
    gap: $gap-2;
    background-color: $white;
    border-radius: $border-radius-4;
    border: $border-width-1 solid $slate-color-6;
    transition: box-shadow 0.25s ease-in-out;

    &:hover,
    &.selected {
        box-shadow: $box-shadow-2;
    }

    &.selected {
        border: $border-width-1 solid $primary-color;
    }

    .v-card-cover-image {
        width: 15rem;
        min-width: 15rem;
        height: 100%;
        font-size: 0;
        line-height: 0;

        img {
            width: 100%;
            height: 100%;
            border-radius: $border-radius-3;
            object-fit: cover;
        }
    }

    .v-card-info {
        flex-grow: 1;
        width: 25rem;
        min-width: 25rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .v-card-info-main {
            .v-card-info-title {
                display: inline-block;
                width: 100%;
                max-width: 100%;
                text-decoration: none;
                color: $grey-color-1;
                @include title-2;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: 4rem;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
            }

            .v-card-organizer {
                @include text-3;
                font-size: 0.8rem;
                margin-bottom: $margin-2;

                .v-card-organizer-name {
                    font-weight: 600;
                    color: $primary-color;
                }
            }

            .v-card-category-list {
                display: flex;
                flex-direction: row;
                gap: $gap-1;
                align-items: center;

                .v-card-category-item {
                    color: $grey-color-2;
                    @include text-3;

                    &:hover {
                        color: $secondary-color;
                    }
                }
            }
        }

        .v-card-info-more {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            .v-card-info-detail {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 0.25rem;

                .v-card-pricing {
                    @include tag-1;
                    color: $secondary-color;
                    background-color: $slate-color-6;

                    &.free {
                        color: $primary-color;
                        background-color: $light-background-white-1;
                    }

                    .highlight {
                        font-weight: 600;
                    }
                }

                .v-card-info-tags {
                    display: flex;
                    flex-direction: row;
                }

                .v-card-info-detail-place-online {
                    display: none;
                }

                .v-card-info-detail-time,
                .v-card-info-detail-place {
                    @include text-2;
                }

                .v-card-info-detail-time {
                    font-weight: 600;
                    color: $primary-color;
                }

                .v-card-info-detail-place,
                .v-card-info-detail-place-online {
                    color: $grey-color-2;
                }
            }

            .v-card-button {
                align-self: flex-end;
                display: flex;
                align-items: center;
                gap: $gap-1;

                .v-card-button-item {
                    background-color: $light-background-transparent-1;
                    border: none;
                }
            }
        }
    }
}

@media screen and (max-width: 48rem) {
    .v-card-container {
        height: unset;
        padding: $padding-2;
        border-radius: $border-radius-2;

        .v-card-cover-image {
            width: 5rem;
            height: 5rem;
            flex-shrink: 0;
            min-width: unset;

            img {
                border-radius: $border-radius-2;
            }
        }

        .v-card-info {
            min-width: unset;
            width: 100%;

            .v-card-info-main {
                .v-card-info-title {
                    @include text-2;
                    font-weight: 500;
                    margin-bottom: $margin-2;
                }

                .v-card-category-list,
                .v-card-organizer {
                    display: none;
                }
            }

            .v-card-info-more {
                .v-card-button {
                    display: none;
                }

                .v-card-info-detail {
                    .v-card-info-detail-time {
                        @include text-mobile-4;
                        //font-weight: 400;
                    }

                    .v-card-info-detail-place {
                        @include text-mobile-4;
                        //font-weight: 500;
                    }

                    .v-card-info-detail-place-online {
                        display: block;
                        @include text-mobile-4;
                    }

                    .v-card-pricing {
                        @include text-mobile-4;
                    }
                }
            }
        }
    }
}