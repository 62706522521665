@import "/src/ui/assets/styles/variables.scss";

.event-list-container {
    margin-top: $padding-3;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $gap-2;

    .event-item-name {
        color: $primary-color;
        @include title-4;
    }
}


.modal-delete-event {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gap-3;
    margin-bottom: $margin-3;

    .modal-delete-event-icon {
        width: 10rem;
        height: auto;
    }
}