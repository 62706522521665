@import "/src/ui/assets/styles/variables";

.past-event-feedback-container {
    width: max(50%, 30rem);

    .past-event-feedback-head {
        margin-bottom: $margin-3;

        .past-event-feedback-title {
            @include title-2;
            margin-bottom: $margin-2;
        }

        .past-event-feedback-created-time {
            @include text-3;
            font-weight: 300;
            color: $slate-color-4;
        }
    }

    .past-event-feedback-content {
        .past-event-feedback-content-wrapper {
            display: flex;
            flex-direction: column;
            gap: $gap-4;
        }

        .past-event-feedback-label {
            margin-bottom: $margin-2;
        }

        .past-event-feedback-rate {
            margin-bottom: $margin-1;
            display: flex;
            align-items: center;
            gap: $gap-3;

            .past-event-feedback-rate-values {
                display: flex;
                gap: $gap-2;

                .past-event-feedback-rate-item {
                    text-align: center;
                    cursor: pointer;
                    font-size: 1.5rem;
                    opacity: 0.5;
                    transition: opacity 0.3s ease-in-out;

                    &:hover,
                    &.selected {
                        opacity: 1;
                    }
                }
            }

            .past-event-feedback-rate-message {
                @include text-2;
                color: $secondary-color;
            }
        }
    }
}