@import '/src/ui/assets/styles/variables.scss';


.user-interest-container {
    flex: 3;
    display: flex;
    align-items: center;
    flex-direction: column;

    .user-interest-head {
        text-align: center;

        .user-interest-title {
            @include title-1;
            color: $secondary-color-tint-2;
            text-align: center;
        }

        .user-interest-desc {
            @include text-3;
            color: $grey-color-2;
            text-align: center;
        }
    }

    .user-interest-content {
        width: max(30%, 30rem);
        margin: 0 auto;
    }
}