@import "/src/ui/assets/styles/variables";

.meeting-banner-container {
    padding: $padding-4;
    border: $border-width-1 solid $slate-color-6;
    border-radius: $border-radius-2;

    .meeting-banner-head {
        display: flex;
        flex-direction: column;
        //align-items: center;
        gap: $gap-2;
        margin-bottom: $margin-4;

        .meeting-banner-event-name {
            @include title-3;
            color: $secondary-color;
        }
    }

    .meeting-banner-main {
        margin-bottom: $margin-3;
        padding-bottom: $padding-3;
        border-bottom: $border-width-1 solid $slate-color-6;

        .meeting-banner-event-thumbnail {
            width: 100%;
            height: auto;
            border-radius: $border-radius-2;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .meeting-banner-description {
            margin-top: $margin-2;
            @include text-2;
            color: $slate-color-2;
        }
    }

    .meeting-banner-join-msg {
        margin-top: $margin-5;
        @include text-2;
        color: $slate-color-3
    }
}