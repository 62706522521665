@import "/src/ui/assets/styles/variables";


.message-chat-container {
    flex: 1;
    display: flex;
    flex-direction: column;

    .message-header {
        height: 5rem;
        width: 100%;
        padding: $padding-5;
        display: flex;
        box-sizing: border-box;
        gap: $gap-2;
        align-items: center;
        border-bottom: 1px solid $grey-color-4;

        .sender-name {
            font-weight: 600;
        }


        .image-container {
            width: 2.5rem;
            height: 2.5rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

    }

    .message-conversation {
        flex: 1;
        padding: $padding-5;
        overflow: scroll;
    }

    .message-input {
        width: 100%;
        padding: $padding-7;
        box-sizing: border-box;
        display: flex;
        gap: $gap-2;

        input {
            padding: $padding-2 $padding-3;
        }

        button {
            height: 100%;
        }
    }
}