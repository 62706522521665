@import '/src/ui/assets/styles/variables.scss';

.header-container {
    height: 100%;
    padding: 0 $padding-4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: border-bottom-width 0.2s ease-in-out;

    &.show-shadow {
        border-bottom: $border-width-1 solid $grey-color-4;
    }

    .header-start,
    .header-end {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: $gap-2;
    }

    .header-start {
        display: flex;
        align-items: center;
        gap: $gap-3;

        .header-logo {
            display: flex;
            align-items: center;
            gap: $gap-2;

            span {
                @include title-3;
                color: $secondary-color;
            }
        }
    }

    .header-start {
        img {
            width: 2rem;
            height: 2rem;
        }
    }

    .header-end {
        .header-avatar {
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 48rem) {
    .header-container {
        .header-start {
            .ant-space-compact {
                display: none;
            }
        }

        .header-end {
            .create-button,
            .chat-page-button,
            .notification-button {
                display: none;
            }
        }
    }
}

.header-dropdown {
    background: $white;
    border-radius: $border-radius-2;
    box-shadow: $box-shadow-1;

    .header-dropdown-top {
        padding: $padding-3;

        .header-dropdown-name {
            @include text-2;
            color: $primary-color;
            margin-bottom: $margin-2;
        }

        .header-dropdown-email {
            @include text-3;
            color: $slate-color-3;
        }
    }
}