@import "src/ui/assets/styles/variables.scss";

.sidebar-tab-view-container {
    width: 20rem;
    background-color: $slate-color-dark-2;
    border-radius: $border-radius-2;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    .sidebar-card-header {
        height: 3rem;
        padding: 0 $padding-2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: $border-width-1 solid $slate-color-2;

        .sidebar-card-header-title {
            @include title-4;
            color: $slate-color-5;
        }
    }

    .sidebar-card-content {
        flex: 1;
        overflow-y: hidden;
    }
}