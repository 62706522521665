@import "/src/ui/assets/styles/variables.scss";

.user-rsv-container {
    width: 100%;

    .user-rsv-head {
        @include heading-3;
        margin-bottom: $margin-3;
    }

    .user-rsv-content {
        .user-rsv-list-header {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: $margin-4;

            .user-rsv-list-search {
                display: flex;
                gap: $gap-1;
                align-items: center;

                .user-rsv-list-search-input {
                    width: 16rem;
                }

                .user-rsv-list-search-result {
                    @include text-3;
                    color: $slate-color-2;
                }
            }

        }

        .user-rsv-list {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: $gap-3;
            align-items: center;
        }
    }
}

@media screen and (max-width: 48rem) {
    .user-rsv-container {
        .user-rsv-head {
            @include text-1;
            font-weight: 500;
        }
    }
}
