@import "/src/ui/assets/styles/variables";

.ticket-finish-container {
    width: 100%;

    .ticket-finish-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: $gap-1;
        margin-bottom: $margin-3;

        img {
            width: 8rem;
            height: auto;
            object-fit: cover;
        }

        .message-primary {
            @include title-2;
            color: $slate-color-1;
        }

        .message-secondary {
            @include text-2;
            color: $slate-color-4;
        }
    }

    .ticket-finish-success {
        width: 100%;
        padding-top: $padding-5;
        display: flex;
        gap: $gap-5;

        .ticket-finish-payment-section {
            flex: 2;
        }

        .ticket-finish-event-section {
            flex: 3;
            height: fit-content;
            padding: $padding-3;
            border-radius: $border-radius-2;
            border: $border-width-1 solid $slate-color-6;

            .ticket-finish-event-section-title {
                @include title-2;
                margin-bottom: $margin-3;
            }

            .ticket-finish-event-info {
                height: 12rem;
                display: flex;
                align-items: flex-start;
                gap: $gap-3;

                .ticket-finish-event-thumbnail {
                    width: 22rem;
                    height: 100%;
                    overflow: hidden;
                    border-radius: $border-radius-2;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .ticket-finish-event-info-detail {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .ticket-finish-event-info-content {

                        .ticket-finish-event-title {
                            @include title-2;
                            margin-bottom: $margin-2;
                            color: $secondary-color;
                        }

                        .ticket-finish-event-time {
                            display: flex;
                            align-items: center;
                            gap: $gap-1;
                            color: $slate-color-4;
                            @include text-3;
                            margin-bottom: $margin-4;
                        }
                    }

                    .ticket-finish-event-button {
                        display: flex;
                        flex-direction: column;
                        gap: $gap-2;
                    }
                }
            }
        }
    }

    .ticket-finish-fail {
        width: max(20%, 24rem);
        margin: $margin-6 auto;
    }

    .ticket-finish-suggest-list {
        margin: $margin-6 0;
    }
}

@media screen and (max-width: 48rem) {
    .ticket-finish-container {
        display: block;

        .ticket-finish-payment-section {
            padding: $padding-3;
        }

        .ticket-finish-event-section {
            margin-bottom: $margin-7;
        }
    }
}