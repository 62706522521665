@import '/src/ui/assets/styles/variables';

.login-form-container {
    display: flex;
    padding: $padding-6;
    flex-direction: column;
    gap: $gap-2;
    background: $white;
    border-radius: $border-radius-3;
    flex-grow: 0.3;
    box-sizing: border-box;

    .signIn-text {
        @include heading-1;
    }

    .signIn-description {
        width: 100%;
        display: flex;
        gap: $gap-2;

        .text {
            @include text-3;
            line-height: 1.5rem;
            color: $grey-color-1;
        }

        .signUp-link {
            .link {
                text-decoration: none;
                @include text-3;
                line-height: 1.5rem;
                color: $secondary-color;

                &:hover {
                    color: $primary-color;
                    font-weight: bold;
                }
            }
        }
    }

    .form-holder {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .googleBtn {
            width: 100%;
            display: flex;
            flex-direction: column;

            .continueText {
                @include text-5;
                width: 100%;
                text-align: center;
                color: $grey-color-1;
                margin-bottom: $margin-3;
            }

            .google-button {
                padding: $padding-5;
                display: flex;
                gap: $gap-1;

                .google-icon {
                    width: 1.5rem;
                    height: auto;
                }
            }
        }
    }
}

@media only screen and (max-width: 48rem) {
    .login-form-container {
        display: flex;
        height: 100%;
        width: 100%;
        flex: 1;
        justify-content: center;
        box-sizing: border-box;
        border-radius: 0;
    }
}
