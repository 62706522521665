@import "/src/ui/assets/styles/variables.scss";

.input-faq-item-container {
    .input-faq-item-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $margin-1;
    }

    .input-faq-item-title {
        @include text-2;
        color: $slate-color-3;
        margin-bottom: $margin-3;
    }
}