@import "/src/ui/assets/styles/variables.scss";

.org-event-dashboard-container {
    padding: $padding-3 $padding-7;
    width: max(80%, 60rem);
    margin: 0 auto;

    .org-event-dashboard-card {
        border-radius: $border-radius-2;
        border: $border-width-1 solid $slate-color-6;
        padding: $padding-3;
    }

    .org-event-dashboard-head {
        .org-event-dashboard-head-message {
            @include title-2;
            color: $slate-color-3;
            margin-bottom: $margin-3;
        }

        .org-event-dashboard-head-content {
            display: flex;
            gap: $gap-2;
            align-items: center;

            .org-event-dashboard-profile-card {
                display: flex;
                align-items: center;
                gap: $gap-3;

                .org-event-dashboard-profile-avatar {
                    width: 3.5rem;
                    height: 3.5rem;
                    border-radius: $border-radius-2;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .org-event-dashboard-profile-content {
                    .org-event-dashboard-profile-name {
                        @include text-1;
                        color: $primary-color;
                        margin-bottom: $margin-1;
                    }

                    .org-event-dashboard-profile-host-account,
                    .org-event-dashboard-profile-host-email {
                        @include text-4;
                        color: $slate-color-3;
                    }
                }

            }

            .org-event-dashboard-statistic {
                width: 100%;
                display: grid;
                column-gap: $gap-5;
                grid-template-columns: auto auto auto auto auto;

                .org-event-dashboard-statistic-card {
                    display: flex;
                    align-items: center;
                    gap: $gap-3;

                    .org-event-dashboard-statistic-card-icon {
                        width: 3rem;
                        height: 3rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $light-background-white-2;
                        color: $primary-color;
                        border-radius: $border-radius-2;
                    }

                    .org-event-dashboard-statistic-card-content {
                        .org-event-dashboard-statistic-card-title {
                            @include text-3;
                            color: $slate-color-4;
                            margin-bottom: $margin-2;
                        }

                        .org-event-dashboard-statistic-card-value {
                            @include title-2;
                            color: $secondary-color;
                        }
                    }
                }
            }
        }
    }

    .org-event-dashboard-create-event-buttons {
        margin-top: $margin-5;
        width: 100%;
        display: flex;
        gap: $gap-3;
        align-items: center;

        .org-event-dashboard-create-event-card {
            flex: 1;
            width: 30rem;
            border-radius: $border-radius-2;
            border: $border-width-1 solid $slate-color-5;
            padding: $padding-3;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: $gap-3;

            .create-event-title {
                @include title-2;
                color: $slate-color-1;
            }

            .create-event-desc {
                @include text-2;
                color: $slate-color-4;
                text-align: center;
            }
        }
    }

    .org-event-dashboard-main-content {
        margin: $margin-5 auto;
        display: flex;
        flex-direction: column;
        gap: $gap-3;

        .event-dashboard-section {

            .event-dashboard-section-title {
                @include title-2;
                color: $primary-color;
                margin-bottom: $margin-3;
            }
        }
    }
}