@import '/src/ui/assets/styles/color.scss';
@import '/src/ui/assets/styles/spacing.scss';

.ant-form {
	.ant-form-item {
		.ant-form-item-control-input {
			.ant-form-item-control-input-content {
				.range-picker {
					width: 100%;
				}
			}
		}
	}
}

.separator {
	height: 6.25rem;
}