@import '/src/ui/assets/styles/spacing.scss';
@import '/src/ui/assets/styles/dimensions.scss';

@mixin layout-container($showSidebar: true) {
    display: grid;
    @if $showSidebar {
        grid-template-areas: "header header" "sidebar main" "footer footer";
        grid-template-columns: $width-sidebar 1fr;
    } @else {
        grid-template-areas: "header header" "main main" "footer footer";
    }
    grid-template-rows: $height-header 1fr auto;
    height: 100%;
}

@mixin layout-container-org() {
    display: grid;
    grid-template-areas: "header header" "sidebar main";
    grid-template-columns: $width-sidebar 1fr;
    grid-template-rows: $height-header 1fr;
    height: 100vh;
}