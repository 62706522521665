@import "/src/ui/assets/styles/variables.scss";

.event-summary-container {
    .event-summary-content {
        display: flex;
        flex-direction: column;
        gap: $gap-3;
        margin-bottom: $margin-3;

        .event-summary-label {
            @include text-2;
            color: $slate-color-2;
            margin-bottom: $margin-2;
        }
    }
}