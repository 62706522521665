@import "/src/ui/assets/styles/variables";

.feedback-form-container {
    width: max(50%, 30rem);

    .feedback-form-head {
        margin-bottom: $margin-3;

        .feedback-form-message {
            margin: $margin-2 0;

            .ant-alert {
                background-color: $light-background-white-1 !important;
                border-color: $primary-color !important;
            }
        }

        .feedback-form-title {
            @include text-2;
            margin-bottom: $margin-4;
            color: $slate-color-2;

            p {
                margin-top: $margin-3;
                @include title-2;
                color: $primary-color;
            }
        }

        .feedback-form-created-time {
            @include text-3;
            font-weight: 300;
            color: $slate-color-3;
        }
    }

    .feedback-form-content {
        .feedback-form-thanks-message {
            margin: $margin-3 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: $gap-2;
            background: $slate-color-7;
            border-radius: $border-radius-2;
            padding: $padding-3;

            img {
                width: 2rem;
                height: auto;
                object-fit: contain;
            }

            .thanks-message-primary {
                @include text-1;
                color: $secondary-color;
            }

            .thanks-message-text {
                @include text-3;
                color: $slate-color-2;
            }
        }

        .feedback-form-rate {
            margin-bottom: $margin-1;
            display: flex;
            align-items: center;
            gap: $gap-3;

            .feedback-form-rate-values {
                display: flex;
                gap: $gap-2;

                .feedback-form-rate-item {
                    text-align: center;
                    cursor: pointer;
                    font-size: 1.5rem;
                    opacity: 0.5;
                    transition: opacity 0.3s ease-in-out;

                    &:hover,
                    &.selected {
                        opacity: 1;
                    }
                }
            }

            .feedback-form-rate-message {
                @include text-2;
                color: $secondary-color;
            }
        }

        .feedback-form-button {
            display: flex;
            align-items: center;
            gap: $gap-2;
        }
    }
}