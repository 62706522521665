@import "/src/ui/assets/styles/variables";

.profile-info-container {
    .profile-info-form {
        .profile-info-fullname {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: $gap-3;

            & > * {
                flex: 1;
            }
        }

        .profile-info-select-address {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: $gap-3;

            & > * {
                flex: 1;
            }
        }

        .profile-info-next-button {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
}
