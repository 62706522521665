@import '/src/ui/assets/styles/variables.scss';

.event-cta-card-container {
    padding: $padding-4;
    border: $border-width-2 solid $slate-color-5;
    border-radius: $border-radius-2;
    @include text-1;

    .event-cta-card-info-list {
        display: flex;
        flex-direction: column;
        gap: $gap-3;
        margin-bottom: $margin-3;

        .info-list-item {
            display: flex;
            flex-direction: row;

            .info-list-item-icon {
                width: 15%;
            }

            .info-list-item-text {
                @include text-2;
                font-weight: 300;
                width: 70%;
            }
        }

    }

    .cta-card-btn {
        margin-top: $margin-3;

        .ticket-option-btn,
        .get-ticket-btn {
            @include button-2;
        }

        .ticket-option-btn {
            margin-bottom: $margin-3;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: $gap-3;
            color: $grey-color-1;

            .highlight {
                font-weight: 800;
            }
        }
    }

}