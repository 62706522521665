@import "/src/ui/assets/styles/variables.scss";

.event-detail-info-container {
    width: min(55%, 55rem);
    margin: 0 auto;
    padding: $padding-5 0;

    .event-detail-info-title {
        @include title-1;
        color: $grey-color-2;
        padding: $padding-3 0;
        margin-bottom: $margin-4;
    }

    .event-detail-info-section {
        margin-bottom: $margin-4;
        border: $border-width-1 solid $grey-color-4;
        border-radius: $border-radius-2;
        padding: $padding-3;

        .event-detail-info-section-head {
            margin-bottom: $margin-3;

            .event-detail-info-section-title {
                @include title-2;
                color: $secondary-color;
                margin-bottom: $margin-1;
            }

            .event-detail-info-section-desc {
                @include text-2;
                color: $slate-color-4;
            }
        }

        .event-detail-info-section-buttons {
            margin-top: $margin-3;
            margin-bottom: $margin-3;
            display: flex;
            align-items: center;
            gap: $gap-2;
        }
    }
}

.add-item-button {
    background: $transparent !important;
}