@import "/src/ui/assets/styles/variables";


.organization-finance-page {
	display: flex;
	height: 100%;
	justify-content: center;
	padding: 4rem 1rem 1rem 1rem;
	.organization-finance-container {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		width: max(80%, 60rem);

		.finance-header {
			.finance-heading {
				@include heading-1;
			}
		}

		.ant-card-body {
			.ant-statistic {
				.ant-statistic-content {
					display: flex;
					align-items: center;
					.ant-statistic-content-prefix {
						display: flex;
					}
				}
			}
		}
	}
}