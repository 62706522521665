@import "src/ui/assets/styles/variables.scss";

.participant-view-container {
    height: 100%;
    width: 100%;
    background-color: $slate-color-dark-2;
    position: relative;
    overflow: hidden;
    border-radius: $border-radius-2;

    .participant-view-content {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .participant-view-avatar {
            width: 6rem;
            height: 6rem;
            z-index: 10;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $border-radius-rounded;
            background-color: $slate-color-dark-1;
            @include title-2;
            color: $slate-color-6;
        }
    }
}