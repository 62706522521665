@import "src/ui/assets/styles/variables.scss";

.player-viewer-container {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: $border-radius-2;

    .player-viewer-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    .player-viewer-pending {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        justify-content: center;

        .player-viewer-pending-msg {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            color: $slate-color-6;
            @include title-3;
            font-weight: 600;
        }
    }
}