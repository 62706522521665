@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import '/src/ui/assets/styles/variables.scss';

.image-slide-container {
    margin: 0 auto;
    width: 100%;

    .image-gallery {
        width: 100%;

        .image-gallery-icon {

            &:hover {
                color: $secondary-color;
            }

            .image-gallery-svg {
                width: 1.5rem;
                height: 1.5rem;
            }
        }

        .image-gallery-thumbnail {

            &.active,
            &:focus,
            &:hover {
                outline: none;
                border-radius: $border-radius-2;
                border: $border-width-3 solid $secondary-color;
            }
        }

        img {
            border-radius: $border-radius-2;
            object-fit: cover;
        }

        .image-gallery-content:not(.fullscreen) {

            .image-gallery-slide {
                height: 32rem;

                @container (max-width: 48rem) {
                    width: 100%;
                    height: 20rem;
                }

                .image-gallery-image {
                    height: 100%;

                    @container (max-width: 48rem) {
                        width: 100%;
                        height: 20rem;
                    }
                }
            }
        }
    }
}