@import '/src/ui/assets/styles/variables.scss';

.ticket-option-container {
    padding: $padding-3;
    background-color: $slate-color-8;
    border-radius: $border-radius-2;

    &.sold-out {
        .ticket-option-main {
            .ticket-price {
                color: $error-color;
            }
        }
    }

    &:hover {
        outline: $border-width-1 solid $slate-color-5;
    }

    .ticket-option-main {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ticket-type,
        .ticket-price {
            @include title-3;
            color: $secondary-color;

            &:hover {
                color: $primary-color-hover;
            }
        }


        .ticket-type {
            display: flex;
            align-items: center;
            gap: $gap-1;
        }

        .ticket-price {
            color: $primary-color;
        }
    }

    .ticket-option-detail {
        margin-top: $margin-3;

        .ticket-due-date,
        .ticket-slot {
            display: block;
            margin-bottom: $margin-2;
        }

        .ticket-option-description {
            margin-top: $margin-3;
            color: $slate-color-2;
            @include text-2;
        }
    }
}