@import '/src/ui/assets/styles/variables';

form {
    &#login {
        width: 100%;

        .login-form-button {
            padding: $padding-5;
        }

        label {
            @include label-1;
        }

        .ant-form-item {
            &.remember-me {
                .ant-form-item-control-input {
                    .ant-form-item-control-input-content {
                        display: flex;
                        justify-content: space-between;

                        .ant-checkbox-wrapper {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }

        .login-form-forgot {
            color: $secondary-color;

            &:hover {
                color: $primary-color;
            }
        }
    }
}
