@import '/src/ui/assets/styles/variables.scss';

.forgot-password-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;

	.forgot-password-wrapper {
		width: 25rem;
		padding: $padding-5;
		box-sizing: border-box;
		box-shadow: $box-shadow-4;
		border-radius: $border-radius-3;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		gap: $gap-5;

		.forgot-password-heading {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: $gap-2;

			.forgot-password-heading-text {
				@include heading-2;
			}

			.forgot-password-description {
				text-align: center;
				@include text-3;
				color: $slate-color-2;
			}
		}

		.forgot-password-form-container {
			width: 100%;

			.send-mail-button-container {
				padding-top: $padding-4;

				.send-mail-button {
					padding: $padding-5;
				}
			}

			.sign-in-link {
				width: 100%;
				text-align: center;

				.link-text {
					color: $slate-color-2;
				}
			}
		}
	}
}
