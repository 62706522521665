@import "src/ui/assets/styles/variables.scss";

.chat-message-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &.local-sender {
        justify-content: flex-end;
        align-items: flex-end;
    }

    .chat-message-sender {
        @include text-3;
        margin-bottom: $margin-2;
        color: $slate-color-4;
    }

    .chat-message-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: $padding-2 $padding-3;
        border-radius: $border-radius-2;
        background-color: $slate-color-1;

        &.local-sender {
            align-items: flex-end;
        }

        .chat-message-content {
            display: inline-block;
            white-space: pre-wrap;
            overflow-wrap: break-word;
            text-align: right;
            color: $slate-color-6;;
        }
    }

    .chat-message-time {
        margin-top: $margin-2;
        @include text-5;
        font-style: italic;
        color: $slate-color-2;
    }
}