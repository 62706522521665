@import '/src/ui/assets/styles/variables.scss';

.image-form-container {
    height: 20rem;

    .ant-upload-wrapper {
        height: 100%;

        .ant-upload.ant-upload-select {
            height: 100%;
            width: 100%;
            border-radius: 1rem;

            .ant-upload {
                .banner-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: $border-radius-6;
                }
            }
        }
    }
}
