@import '/src/ui/assets/styles/variables';

.login-wrapper {
    background-image: url('../../assets/images/background-login.jpg');
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;

    .background-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.97) 0%, rgba(0, 0, 0, 0.97) 100%),
        url('../../assets/images/background-login.jpg'),
        50% / cover no-repeat;
        opacity: 0.8;
        background-size: cover;
    }

    .login-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;

        .left-content {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .right-content {
            flex: 1;
            display: flex;
            align-items: center;
        }
    }

}

@media only screen and (max-width: 48rem) {
    .login-wrapper {
        .login-container {
            .left-content {
                display: none;
            }

            .right-content {
                flex: 1;
            }
        }
    }
}