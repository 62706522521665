$primary-color: #147451;
$primary-color-hover: #094c3a;
$primary-color-chat: #3A7659;

$primary-color-tint-1: #5de1a9;
$primary-color-tint-2: #98e6c6;
$primary-color-tint-3: #f4ffff;
$primary-color-tint-4: #fcfffd;

$primary-color-shade-1: #193c32;
$primary-color-shade-2: #102c27;
$primary-color-shade-2: #051a15;

$secondary-color: #282e37;
$secondary-color-hover: #2f353e;

$secondary-color-tint-1: #31383e;
$secondary-color-tint-2: #636d74;
$secondary-color-tint-3: #abb3b9;

$grey-color-1: #7d7d7d;
$grey-color-2: #ababab;
$grey-color-3: #c5c5c5;
$grey-color-4: #eaeaea;
$grey-color-5: #f1f1f1;
$grey-color-6: #f7f8f8;

$slate-color-1: #424B52;
$slate-color-2: #616E78;
$slate-color-3: #80919E;
$slate-color-4: #95a3ae;
$slate-color-5: #eaeced;
$slate-color-6: #f0f2f4;
$slate-color-7: #f7f9fa;
$slate-color-8: #f9fafb;
$slate-color-9: #aebac3;

$slate-color-dark-1: #1b1c21;
$slate-color-dark-2: #23282c;

$light-background-white-1: #edf4f2;
$light-background-white-2: #f6faf8;
$light-background-white-3: #e7f1ee;

$light-background-transparent-1: rgba(255, 255, 255, 0.75);
$light-background-transparent-2: rgba(255, 255, 255, 0.5);
$light-background-transparent-3: rgba(255, 255, 255, 0.25);

$dark-background-transparent-1: rgba(0, 0, 0, 0.75);

$error-color: #b1232d;
$link-color: #3659e3;

$white: #fff;
$black: #000;
$transparent: rgba(255, 255, 255, 0);
$accent-color-1: #fff7f9;

:export {
    primaryColor: $primary-color;
    primaryColorHover: $primary-color-hover;
    secondaryColor: $secondary-color;
    secondaryColorHover: $secondary-color-hover;
    greyColor3: $grey-color-3;
    greyColor4: $grey-color-4;
    greyColor5: $grey-color-5;
    slateColor4: $slate-color-4;
    slateColor5: $slate-color-5;
    slateColor6: $slate-color-6;
    white: $white;
    slateColor2: $slate-color-2;
    darkBackgroundTransparent1: $dark-background-transparent-1;
}
