@import '/src/ui/assets/styles/variables';


.personal-preference-container {
    width: 65%;

    .user-interest-head {
        width: 100%;

        .user-interest-title {
            @include heading-3;
            color: $secondary-color;
            margin-bottom: $margin-1;
        }

        .user-interest-desc {
            @include text-3;
            color: $slate-color-3;
        }
    }
}