@import "src/ui/assets/styles/variables.scss";

.chat-input-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $gap-1;
    margin: 0 $margin-2;

    .chat-input-message {
        background-color: $slate-color-dark-2;
        color: $slate-color-6;
        border-color: $slate-color-1;

        &::placeholder {
            color: $slate-color-1;
        }

        &:focus {
            border-color: $slate-color-6;
        }
    }

    .chat-input-button {
        color: $slate-color-1;

        &:hover {
            color: $slate-color-6 !important;
        }
    }
}