@import "/src/ui/assets/styles/variables";

.organizations-page-container {
    width: 50rem;
    margin: 0 auto;

    .organizations-page-head {
        padding: $padding-4 0;
        margin-bottom: $margin-5;

        .organizations-page-title {
            @include title-1;
            margin-bottom: $margin-3;
        }

        .organizations-page-desc {
            @include text-2;
            color: $slate-color-4;
        }
    }
}