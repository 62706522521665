@import "/src/ui/assets/styles/variables.scss";

.qr-scanner-container {
    .qr-scanner-control {
        display: flex;
        align-items: center;
        gap: $gap-3;
        margin-bottom: $margin-3;
    }

    .qr-scanner-cam {
        color: $primary-color;

        svg {
            color: $primary-color !important;
        }

        path {
            color: $primary-color !important;
            stroke: $primary-color !important;
        }
    }
}