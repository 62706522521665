@import "/src/ui/assets/styles/variables";

.ticket-buyer-info-container {
    .ticket-buyer-info-head {
        padding: $padding-3;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $light-background-white-2;
        border-radius: $border-radius-2;

        p {
            @include title-3;
        }

        button {
            color: $primary-color;
            border-color: $primary-color;
        }
    }

    .ticket-buyer-info-form-container {
        padding: $padding-6;

        .ticket-buyer-info-form {
            width: 30rem;
            margin: 0 auto;
        }
    }
}