@import "/src/ui/assets/styles/variables.scss";

.h-card-container {
    width: 100%;
    background-color: $white;
    border-radius: $border-radius-3;
    border: $border-width-1 solid $slate-color-6;
    transition: box-shadow 0.25s ease-in-out;

    .h-card-button {
        display: none;
    }

    &:hover {
        box-shadow: $box-shadow-1;

        .h-card-button {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
            display: flex;
            flex-direction: row;
            gap: $gap-1;

            .h-card-button-item {
                background-color: $light-background-transparent-1;
                backdrop-filter: blur($blur-1);
                border: none;
            }
        }
    }

    .h-card-tag {
        width: fit-content;
        @include tag-1;
    }

    .h-card-head {
        width: 100%;
        height: 11rem;
        position: relative;

        .h-card-event-format {
            position: absolute;
            top: 1rem;
            right: 1rem;
            background-color: rgba(255, 255, 255, 0.75);
            backdrop-filter: blur(2px);
        }

        .h-card-cover-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: $border-radius-3 $border-radius-3 0 0;
        }

    }

    .h-card-info {
        padding: $padding-2 $padding-3;

        .h-card-info-title {
            height: 4.75rem;
            max-width: 100%;
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;

            .h-card-info-title-link {
                @include title-2;
                color: $grey-color-1;
                text-decoration: none;

                &:hover {
                    color: $primary-color;
                }
            }
        }

        .h-card-info-detail {
            margin: 0.25rem 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 0.25rem;

            .h-card-info-detail-time,
            .h-card-info-detail-place {
                @include text-3;
                color: $grey-color-1;
            }

            .h-card-info-detail-time {
                font-weight: 600;
            }


        }

    }

    .h-card-bottom {
        display: flex;
        flex-direction: column;
        gap: $gap-1;
        padding: 0 $padding-3;
        margin-bottom: $margin-2;

        .h-card-pricing {
            color: $grey-color-1;
            background-color: $grey-color-4;

            &.free {
                color: $primary-color;
                background-color: $light-background-white-1;
            }

            .highlight {
                font-weight: 600;
            }
        }

        .h-card-category-list {
            display: flex;
            flex-direction: row;
            gap: $gap-1;
            align-items: center;

            .h-card-category-item {
                color: $grey-color-2;
                @include text-3;

                &:hover {
                    color: $secondary-color;
                }
            }
        }
    }
}
