@import "/src/ui/assets/styles/variables.scss";

.my-events-page {
    display: flex;
    justify-content: center;
    padding: $margin-6 $padding-3 $padding-3 $padding-3;

    .my-event-page-container {
        display: flex;
        flex-direction: column;
        gap: $gap-2;
        width: max(95%, 70rem);

        .host-events-heading {
            @include heading-1;
        }

        .search-bar {
            display: flex;
            align-items: center;
            gap: $gap-2;
        }
    }
}