@import "/src/ui/assets/styles/variables";

.ticket-order-section-container {
    margin-bottom: $margin-4;

    .ticket-section-title {
        margin-bottom: $margin-2;
    }

    .ticket-section-content {
        .ticket-section-item {
            margin-bottom: $margin-2;
            display: flex;
            align-items: flex-start;
            gap: $gap-2;

            .ticket-section-item-label {
                flex-basis: 8rem;
                @include title-4;
                font-weight: 300;
                color: $slate-color-3;
            }

            .ticket-section-item-value {
                flex: 1;
                @include title-4;
                color: $secondary-color;
            }
        }
    }
}
