@import "/src/ui/assets/styles/variables";

.ticket-payment-container {
    display: flex;
    align-items: flex-start;
    gap: $gap-2;

    .ticket-payment-main {
        flex: 2;

        .ticket-payment-section {
            margin-bottom: $margin-4;

            .ticket-payment-section-title {
                padding: $padding-2;
                margin-bottom: $margin-2;
                @include title-3;
                background-color: $light-background-white-2;
                border-radius: $border-radius-2;
            }
        }

        .ticket-payment-voucher {
            .ticket-payment-voucher-input {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: $margin-3 auto;
                width: 28rem;
            }

            .ticket-payment-voucher-list {
                .ticket-payment-voucher-list-title {
                    @include text-2;
                    color: $slate-color-1;
                    margin-bottom: $margin-2;
                }

                .ticket-payment-voucher-list-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: $gap-3;
                }
            }

            .ticket-payment-applied-voucher {
                .ticket-payment-applied-voucher-title {
                    @include text-2;
                    color: $slate-color-1;
                    margin: $margin-3 0;
                }

                .ticket-payment-applied-voucher-list {
                    margin: $margin-2 0;
                    display: flex;
                    align-items: center;
                    gap: $gap-3;

                    .ticket-payment-applied-voucher-item {
                        display: flex;
                        gap: $gap-1;
                        align-items: center;
                        background-color: $slate-color-7;
                        padding: $padding-2;
                        border-radius: $border-radius-2;

                        &:hover {
                            background-color: $slate-color-6;
                        }

                        .applied-voucher-icon {
                            color: $slate-color-2;
                        }

                        .applied-voucher-content {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            gap: $gap-1;

                            .applied-voucher-code {
                                @include text-2;
                                font-weight: 500;
                            }

                            .applied-voucher-value {
                                @include text-2;
                                font-weight: 300;
                            }
                        }
                    }
                }
            }
        }

        .ticket-payment-method {
            .ticket-payment-method-select {
                display: flex;
                align-items: center;
                gap: $gap-3;

                .ticket-payment-method-btn {
                    display: flex;
                    align-items: center;
                    gap: $gap-1;

                    img {
                        width: 1.5rem;
                        height: auto;
                        object-fit: cover;
                    }

                    &.ant-btn-primary {
                        background-color: $slate-color-5;
                        color: $slate-color-1;
                    }
                }
            }
        }

        .ticket-payment-note {
            margin-top: $margin-4;
            @include text-3;
            color: $slate-color-3;
            font-weight: 300;

            .highlight {
                font-weight: 600;
                margin-bottom: $margin-1
            }
        }
    }

    .ticket-rsv-navigation {
        flex: 1;

        .ticket-cart-button {
            margin-top: $margin-5;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: $gap-2;
            align-items: center;
            justify-content: space-between;
        }
    }
}

@media screen and (max-width: 48rem) {
    .ticket-payment-container {
        display: block;

        .ticket-payment-main {
            .ticket-payment-section {
                margin-bottom: 0;
            }

            .ticket-payment-section.ticket-payment-method {
                margin-bottom: $margin-3;

                .ticket-payment-method-select {
                    width: 100%;
                    flex-wrap: wrap;
                    padding: $padding-3;
                    gap: 0.5rem;
                    box-sizing: border-box;
                }
            }

            .ticket-payment-section.ticket-payment-note {
                padding: $padding-3;
                margin: 0;
            }

            .ticket-payment-section.ticket-payment-voucher {
                .ticket-payment-voucher-input {
                    padding: $padding-3;

                    .ant-space-compact {
                        width: 100%;
                    }
                }

                .ticket-payment-voucher-list {
                    padding: $padding-3;
                }

                .ticket-payment-applied-voucher {
                    padding: $padding-3;
                }
            }
        }
    }
}