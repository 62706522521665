@import '/src/ui/assets/styles/variables.scss';

.home-container {
    max-width: 80rem;
    margin: 0 auto;
    padding: 0 $padding-6;
    display: flex;
    flex-direction: column;
    gap: $gap-5;

    .home-section {
        margin-bottom: $margin-6;

        .home-section-header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .home-section-title {
                @include title-2;
                color: $slate-color-1;
                margin-bottom: $margin-3;
                display: flex;
                align-items: center;
                gap: $gap-1;

                img {
                    width: 20px;
                    height: 20px;
                    object-fit: contain;
                }

                &:hover {
                    color: $primary-color-hover;
                }
            }

            .home-section-see-more-btn {
                color: $grey-color-2;
                display: flex;
                align-items: center;

                &:hover {
                    color: $secondary-color;
                }
            }
        }
    }
}

@media screen and (max-width: 48rem) {
    .home-container {
        .home-section {
            .horizontal-event-list-container {
                .event-list-grid {
                    grid-template-columns: 1fr;
                }
            }

            .organizer-master-list-container {
                flex-direction: column;
            }
        }
    }
}