@import "src/ui/assets/styles/variables.scss";

.ils-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background-color: $slate-color-dark-1;

    .ils-main {
        flex: 1;
        padding: $padding-2;
        display: flex;
        gap: $gap-1;
        overflow-y: hidden;

        .ils-presenter-view,
        .hls-view {
            flex: 1;
            display: flex;
        }
    }

    .ils-bottom {
        height: 4rem;
        display: flex;
        align-items: center;
    }
}