@import "/src/ui/assets/styles/variables";

.event-agenda-container {
    .event-agenda-content {
        display: flex;
        flex-direction: column;
        gap: $gap-3;

        .event-agenda-list {
            display: flex;
            flex-direction: column;
            gap: $gap-3;

            .event-agenda-item {

                .agenda-item-button {
                    margin-top: $margin-2;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: $gap-1;
                }
            }
        }
    }
}