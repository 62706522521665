@import '/src/ui/assets/styles/variables.scss';


.tickets-page-container {
    height: 100%;
    width: max(80%, 60rem);
    margin: 0 auto;

    .ticket-page-wrapper {
        padding: 4rem 1rem 1rem 1rem;

        .ticket-page-contents {
            display: flex;
            flex-direction: column;
            gap: $gap-2;

            .go-to-bank-link {
                color: $slate-color-1;
            }

            .ticket-page-header {
                @include heading-1;
            }

            .ticket-page-tabs {
                padding-top: $padding-4;
            }
        }
    }
}

.ant-modal-confirm-btns {
    display: flex;
    justify-content: flex-end;
}